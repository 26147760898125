import axios from "axios";

import { API_PATH } from "../config/Config";

export const SOZLESME_LIST_GETIR = "SOZLESME_LIST_GETIR";
export const KOY_LIST_GETIR = "KOY_LIST_GETIR";
export const URETICI_GETIR = "URETICI_GETIR";

export const SOZLESME_CREATE_SUCCESS = "SOZLESME_CREATE_SUCCESS";
export const SOZLESME_CREATE_FAIL = "SOZLESME_CREATE_FAIL";
export const SOZLESME_DELETE_SUCCESS = "SOZLESME_DELETE_SUCCESS";
export const SOZLESME_DELETE_FAIL = "SOZLESME_DELETE_FAIL";
export const DELETE = "DELETE";
export const SOZLESME_TUR_LIST = "SOZLESME_TUR_LIST";
export const URETICI_FIND_SUCCESS = "URETICI_FIND_SUCCESS";
export const URETICI_FIND_ERROR = "URETICI_FIND_ERROR";

export function getSozlesmeListesi(bdate, koyId) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/sozlesme/findByTarih/` + bdate + `/` + koyId)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: SOZLESME_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (SÖZLEŞME LİSTESİ)");
            });
    };
}

export function getKoyListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/koy/findall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: { koyAd: "Tüm Köyler", id: "Tüm Köyler" } }].concat(result))
            .then(result =>
                dispatch({
                    type: KOY_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY LİSTESİ)");
            });
    };
}

function sozlesmeKaydetmeKontrolu(sonuc, sozlesmeBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: SOZLESME_CREATE_SUCCESS,
            payload: "SUCCESS",
            ureticiStatus: ""
        };
    } else {
        return {
            type: SOZLESME_CREATE_FAIL,
            payload: "ERROR"
        };
    }
}

export function sozlesmeKaydet(sozlesmeBilgileri) {
    sozlesmeBilgileri.bdate = "";
    sozlesmeBilgileri.filtered = [];
    sozlesmeBilgileri.sozlesmeDurumList = [];
    sozlesmeBilgileri.ureticiTcKimlikNo = "";

    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/sozlesme/create`, {
            method: "POST",
            data: sozlesmeBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(sozlesmeKaydetmeKontrolu(result, sozlesmeBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (SÖZLEŞME KAYDETME)");
            });
    };
}

function silmeKontrolu(sonuc, sozlesmeBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: SOZLESME_DELETE_SUCCESS,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: SOZLESME_DELETE_FAIL,
            payload: "ERROR"
        };
    }
}

export function deleteSozlesme(sozlesmeBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/sozlesme/delete`, {
            method: "DELETE",
            data: sozlesmeBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => dispatch(silmeKontrolu(result, sozlesmeBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (SÖZLEŞME SİL)");
            });
    };
}
function findUreticiKontrol(sonuc, ureticiTcKimlikNo) {
    if (sonuc !== "") {
        return {
            type: URETICI_FIND_SUCCESS,
            payload: sonuc,
            status: "SUCCESS"
        };
    } else if (sonuc === "") {
        return {
            type: URETICI_FIND_ERROR,
            payload: "ERROR"
        };
    }
}

export function findUretici(tcKimlikNo) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/uretici/findByTc/` + tcKimlikNo)

            .then(result => result.data)
            .then(result => dispatch(findUreticiKontrol(result, tcKimlikNo)))
            .catch(() => {
                alert("SİSTEM HATASI! (ÜRETİCİ BULMA)");
            });
    };
}

export function sozlesmeTurList() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/sozlesme/sozlesmeTur`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: "" }].concat(result))
            .then(result =>
                dispatch({
                    type: SOZLESME_TUR_LIST,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (SÖZLEŞME TÜR LİSTESİ)");
            });
    };
}
