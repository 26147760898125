import axios from "axios";
import moment from "moment";

import { API_PATH } from "../config/Config";

export const KAZAN_LIST_GETIR = "KAZAN_LIST_GETIR";
export const ISLEM_LIST_GETIR = "ISLEM_LIST_GETIR";
export const DEVAM_EDEN_ISLEM_LIST_GETIR = "DEVAM_EDEN_ISLEM_LIST_GETIR";
export const CREATE_FAIL_YENI_DISTILASYON = "CREATE_FAIL_YENI_DISTILASYON";
export const CREATE_SUCCESS_YENI_DISTILASYON = "CREATE_SUCCESS_YENI_DISTILASYON";
export const CREATE_FAIL_DISTILASYON_BITIR = "CREATE_FAIL_DISTILASYON_BITIR";
export const CREATE_SUCCESS_DISTILASYON_BITIR = "CREATE_SUCCESS_DISTILASYON_BITIR";

export function getKazanListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kazan/findall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: "", display: "(Lütfen kazan bilgisini seçiniz)" }].concat(result))
            .then(result =>
                dispatch({
                    type: KAZAN_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KAZAN LİSTESİ)");
            });
    };
}

export function getIslemTurListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/islemTurFindall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result =>
                dispatch({
                    type: ISLEM_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (İŞLEM TÜR LİSTESİ)");
            });
    };
}

export function getDevamEdenIslemlerList() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/findNullBitisZamani`)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: DEVAM_EDEN_ISLEM_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (DEVAM EDEN İŞLEMLER LİSTESİ)");
            });
    };
}

function islemiBaslatKaydetKontrolu(sonuc, islemBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS_YENI_DISTILASYON,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL_YENI_DISTILASYON,
            payload: "ERROR"
        };
    }
}

export function islemiBaslatKaydet(islemBilgileri) {
    islemBilgileri.filtered = [];
    islemBilgileri.baslamaZaman = moment(islemBilgileri.baslamaZaman).format();

    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/distilasyonIslemler/create`, {
            method: "POST",
            data: islemBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(islemiBaslatKaydetKontrolu(result, islemBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (İŞLEM KAYDETME)");
            });
    };
}

export function islemBitisKaydet(islemBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/distilasyonIslemler/create`, {
            method: "POST",
            data: islemBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(islemBitisKaydetKontrolu(result, islemBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (İŞLEM BİTİRME)");
            });
    };
}

function islemBitisKaydetKontrolu(sonuc, islemBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS_DISTILASYON_BITIR,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL_DISTILASYON_BITIR,
            payload: "ERROR"
        };
    }
}
