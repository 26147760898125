import { CREATE_SUCCESS_KAZAN, CREATE_FAIL_KAZAN, LIST_GETIR_KAZAN, DELETE_SUCCESS_KAZAN, DELETE_FAIL_KAZAN } from "./KazanYonetimAction";

export default function kazanYonetimReducer(
    state = {
        kazanList: [],
        koyList: [],
        kayitDurumu: {},
        silmeDurum: {},
        fetching: true,
        kazan: {},
        error: ""
    },
    action
) {
    switch (action.type) {
        case LIST_GETIR_KAZAN:
            return {
                ...state,
                kazanList: action.payload
            };
        case CREATE_SUCCESS_KAZAN:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case CREATE_FAIL_KAZAN:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case DELETE_SUCCESS_KAZAN:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case DELETE_FAIL_KAZAN:
            return {
                ...state,
                silmeDurum: action.payload
            };

        default:
            return state;
    }
}
