import React from "react";
import "./TeslimAlinanGulMiktariSayfasi.css";
import { getKoyListesi, getTeslimAlinanGulMiktariListesi } from "../teslimAlinanGulMiktariSayfasi/TeslimAlinanGulMiktariAction";
import { Icon, Table, Button, Input, Menu, Label, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import ChangeDate from "../changeDate/ChangeDate";

class TeslimAlinanGulMiktariSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            ad: "",
            soyad: "",
            tcKimlikNo: "",
            tarihSaat: "",
            bulunduguKoy: "",
            gulMiktari: "",
            ureticiKoyID: "Tüm Köyler",
            teslimTarihi: new Date(),
            open: false,
            filter: "",
            filtered: [],
            showTeslimZaman: [],
        };
    }

    submit = () => {
        this.props.getTeslimAlinanGulMiktariListesi(this.state.teslimTarihi.toISOString(), this.state.ureticiKoyID);
    };

    handleChangeForm = (e, { value }) => {
        //for combobox
        this.setState({ ureticiKoyID: value });
    };

    handleDate = date => {
        this.setState({ teslimTarihi: date._d });
    };

    componentDidMount() {
        this.props.getKoyListesi();
        this.props.getTeslimAlinanGulMiktariListesi(this.state.teslimTarihi.toISOString(), this.state.ureticiKoyID);
    }

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList
        });

        var arr;
        arr = [];

        let i;
        for(i = 0; i < next.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList.length; i++) {
            if(next.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList[i].tarihSaat != null) {
                let today = next.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList[i].tarihSaat;
                let todayNew = ChangeDate.changeDate(today);

                const data = {
                    id: next.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList[i].id,
                    date : todayNew
                }
                arr.push(data);
            }
        }

        this.setState({showTeslimZaman: arr});
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList;
        updatedList = this.props.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList.filter(function(item) {
            return item.ad.toLowerCase().includes(filter) || item.soyad.toLowerCase().includes(filter) || item.tcKimlikNo.includes(filter) || item.koyAd.includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>TESLİM ALINAN GÜL MİKTARI</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <br />
                <div className="TeslimAlinanGulMiktari">
                    <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                        TARİH:
                    </Label>
                    <Input>
                        <Datetime
                            name="teslimTarihi"
                            timeFormat={false}
                            value={this.state.teslimTarihi}
                            defaultValue={new Date()}
                            onChange={this.handleDate}
                            dateFormat="DD/MM/YYYY"
                            closeOnSelect
                            required
                        />
                    </Input>

                    <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                        KÖY:
                    </Label>
                    <Dropdown
                        placeholder="Köy seçin."
                        required
                        style={{ margin: 5 }}
                        selection
                        value={this.state.ureticiKoyID}
                        onChange={this.handleChangeForm}
                        options={this.props.state.UreticiYonetimReducer.koyList.map(team => ({ key: team.value.id, value: team.value.id, text: team.value.koyAd }))}
                    />

                    <Button onClick={this.submit} color="blue">
                        BUL
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>TESLİM EDENİN ADI SOYADI</Table.HeaderCell>
                                <Table.HeaderCell>T.C. KİMLİK NO</Table.HeaderCell>
                                <Table.HeaderCell>TESLİM ZAMANI</Table.HeaderCell>
                                <Table.HeaderCell>BULUNDUĞU KÖY</Table.HeaderCell>
                                <Table.HeaderCell>GÜL MİKTARI (KG)</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>
                                        {post.ad} {post.soyad}
                                    </Table.Cell>
                                    <Table.Cell>{post.tcKimlikNo}</Table.Cell>
                                    <Table.Cell>
                                        {" "}
                                        {this.state.showTeslimZaman[this.state.filtered.indexOf(post)].date}
                                    </Table.Cell>
                                    <Table.Cell>{post.koyAd}</Table.Cell>
                                    <Table.Cell>{post.miktar}</Table.Cell>
                                </Table.Row>
                            ))}

                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>TOPLAM MİKTAR (KG):</th>
                                <th>
                                    {this.props.state.TeslimAlinanGulMiktariReducer.teslimEdilenGulList
                                        .map(line => line.miktar)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                            </tr>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getKoyListesi,
    getTeslimAlinanGulMiktariListesi
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeslimAlinanGulMiktariSayfasi);
