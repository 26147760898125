import React, { Component } from "react";
import { login } from "./UserAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./Login.css";
import { Button, Form, Image } from "semantic-ui-react";
import YoneticiAnaSayfa from "../yoneticiAnaSayfa/YoneticiAnaSayfa";

class Login extends Component {
    handleLogout = () => {
        this.props.logout();
    };

    handleSubmit = event => {
        event.preventDefault();
        const user = event.target.elements.username.value;
        const pass = event.target.elements.password.value;

        if (user.lenght < 3 || pass.lenght < 3) {
        } else {
            this.props.login(user, pass);
        }
    };

    render() {
        if (this.props.state.UserReducer.isAuthenticated) {
            return (
                <div>
                    {" "}
                    <YoneticiAnaSayfa />
                </div>
            );
        } else {
            return (
                <div>
                    <div className="container">
                        <Form {...this.props} onSubmit={this.handleSubmit} position="relative">
                            <Form.Field>
                                <br /><br />
                                <img src="../images/logo-ercetin.png" alt=" ercetin logo" />
                                <br />
                                <br />
                                <br />
                                <label style={{ fontSize: 18 }}>T.C. Kimlik No:</label>
                                <br />
                                <input style={{ width: "35%", textAlign: "center", borderRadius: 30, fontSize: 16 }} name="username" type="text" minLength="11" className="username" maxLength="11" />
                                <br />
                                <br />
                                <label style={{ fontSize: 18 }}>Şifre:</label>
                                <br />
                                <input style={{ width: "35%", textAlign: "center", borderRadius: 30, fontSize: 16 }} name="password" type="password" className="password" maxLength="10" />
                                <br />
                                <br />
                                <div style={divStyle}>{this.props.state.UserReducer.error}</div>
                            </Form.Field>
                            <Button style={{ backgroundColor: "#be2828", color: "white", fontSize: 16, borderRadius: 30 }} type="submit" value="login">
                                GİRİŞ
                            </Button>
                        </Form>
                    </div>
                    <Image
                        size="tiny"
                        src="../images/BiSoft_Logo.jpg"
                        style={{ position: "absolute", marginBottom: "20px", marginLeft: "auto", marginRight: "auto", left: 0, right: 0, bottom: 0 }}
                    ></Image>
                </div>
            );
        }
    }
}

const divStyle = {
    color: "red"
};

const mapDispatchToProps = {
    login
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
