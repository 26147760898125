import axios from "axios";

import { API_PATH } from "../config/Config";

export const LIST_GETIR_KOY = "LIST_GETIR_KOY";
export const CREATE_SUCCESS_KOY = "CREATE_SUCCESS_KOY";
export const CREATE_FAIL_KOY = "CREATE_FAIL_KOY";
export const DELETE_SUCCESS_KOY = "DELETE_SUCCESS_KOY";
export const DELETE_FAIL_KOY = "DELETE_FAIL_KOY";
export const DELETE = "DELETE";

export function getKoyListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/koy/findall`)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: LIST_GETIR_KOY,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY LİSTESİ)");
            });
    };
}

function koyKaydetmeKontrolu(sonuc, koyBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS_KOY,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL_KOY,
            payload: "ERROR"
        };
    }
}

export function koyKaydet(koyBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/koy/create`, {
            method: "POST",
            data: koyBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(koyKaydetmeKontrolu(result, koyBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY KAYDETME)");
            });
    };
}

function silmeKontrolu(sonuc, koyBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: DELETE_SUCCESS_KOY,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: DELETE_FAIL_KOY,
            payload: "ERROR"
        };
    }
}

export function deleteKoy(koyBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");
        axios(`${API_PATH}/api/koy/delete`, {
            method: "DELETE",
            data: koyBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => dispatch(silmeKontrolu(result, koyBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY SİLME)");
            });
    };
}
