import axios from "axios";

import { API_PATH } from "../config/Config";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

function loginSuccess(hash, veri) {
    if (veri.status === 200 && veri.data.role === "YONETICI") {
        sessionStorage.setItem("sessionToken", hash);
        sessionStorage.setItem("kullaniciId", veri.data.id);
        sessionStorage.setItem("kullaniciRolu", veri.data.role);
        sessionStorage.setItem("kullaniciTcKimlikNo", veri.data.username);
        return {
            type: LOGIN_SUCCESS,
            payload: veri.data
        };
    }
}

function loginFail(error) {
    sessionStorage.removeItem("sessionToken");
    return {
        type: LOGIN_FAILURE,
        payload: "T.C. Kimlik No veya Şifre Yanlış!"
    };
}

function sessionLogout() {
    sessionStorage.removeItem("sessionToken");
    return {
        type: LOGOUT
    };
}

export function login(username, password) {
    const hash = new Buffer(`${username}:${password}`).toString("base64");

    const headerToken = hash;
    return dispatch => {
        axios
            .get(`${API_PATH}/api/user/userDetail`, { headers: { Authorization: `Basic ${headerToken}` } })
            .then(result => result)
            .then(result => dispatch(loginSuccess(hash, result)))
            .catch(error => dispatch(loginFail(error)));
    };
}

export function logout() {
    return dispatch => {
        dispatch(sessionLogout());
    };
}
