import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import "./BiSideBar.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

import YoneticiBitenIslemlerSayfasi from "../yoneticiBitenIslemlerSayfasi/YoneticiBitenIslemlerSayfasi";
import UretilenUrunlerSayfasi from "../uretilenUrunlerSayfasi/UretilenUrunlerSayfasi";
import SozlesmeYonetimSayfasi from "../sozlesmeYonetimSayfasi/SozlesmeYonetimSayfasi";
import AlacakVerecekDurumSayfasi from "../alacakVerecekDurumSayfasi/AlacakVerecekDurumSayfasi";
import UreticiYonetimSayfasi from "../ureticiYonetimSayfasi/UreticiYonetimSayfasi";
import KoyYonetimSayfasi from "../koyYonetimSayfasi/KoyYonetimSayfasi";
import KantarYonetimSayfasi from "../kantarYonetimSayfasi/KantarYonetimSayfasi";
import KazanYonetimSayfasi from "../kazanYonetimSayfasi/KazanYonetimSayfasi";
import AracYonetimSayfasi from "../aracYonetimSayfasi/AracYonetimSayfasi";
import TeslimAlinanGulMiktariSayfasi from "../teslimAlinanGulMiktariSayfasi/TeslimAlinanGulMiktariSayfasi";
import KantardaToplananGulYonetimSayfasi from "../kantardaToplananGulYonetimSayfasi/KantardaToplananGulYonetimSayfasi";
import YoldakiAraclarYonetimSayfasi from "../yoldakiAraclarYonetimSayfasi/YoldakiAraclarYonetimSayfasi";
import FabrikayaUlasanAraclarYonetimSayfasi from "../fabrikayaUlasanAraclarYonetimSayfasi/FabrikayaUlasanAraclarYonetimSayfasi";
import DevamEdenIslemlerYonetimSayfasi from "../devamEdenIslemlerYonetimSayfasi/DevamEdenIslemlerYonetimSayfasi";
import UyariMesajlariYonetimSayfasi from "../uyariMesajlariYonetimSayfasi/UyariMesajlariYonetimSayfasi";
import SifreDegisimSayfasi from "../sifreDegisimEkrani/SifreDegisimSayfasi";
import YoneticiAnaSayfa from "../yoneticiAnaSayfa/YoneticiAnaSayfa";

import { login, logout } from "../login/UserAction";
import { Link } from "react-router-dom";

class BiSideBar extends React.Component {
    showSettings(event) {
        event.preventDefault();
    }

    handleLogout = () => {
        this.props.logout();
    };

    render() {
        return (
            <div>
                <Menu>
                    <a id="home" className="menu-item" href="YoneticiAnaSayfa">
                        Anasayfa
                    </a>
                    <a id="arac" className="menu-item" href="aracYonetimSayfasi">
                        Araçlar
                    </a>
                    <a id="kantarYonetim" className="menu-item" href="kantarYonetimSayfasi">
                        Kantarlar
                    </a>
                    <a id="kazan" className="menu-item" href="kazanYonetimSayfasi">
                        Kazanlar
                    </a>
                    <a id="koy" className="menu-item" href="koyYonetimSayfasi">
                        Köyler
                    </a>
                    <a id="contact" className="menu-item" href="ureticiYonetimSayfasi">
                        Üreticiler
                    </a>
                    <a id="sozlesmeSayfasi" className="menu-item" href="sozlesmeYonetimSayfasi">
                        Sözleşmeler
                    </a>
                    <a id="alacakVerecekSayfasi" className="menu-item" href="alacakVerecekDurumSayfasi">
                        Alacak Verecek Durumu
                    </a>
                    <a id="bitenIslemler" className="menu-item" href="yoneticiBitenIslemlerSayfasi">
                        Biten İşlemler
                    </a>
                    <a id="devamEdenIslemler" className="menu-item" href="devamEdenIslemlerYonetimSayfasi">
                        Devam Eden İşlemler
                    </a>
                    <a id="kantardaToplananGul" className="menu-item" href="kantardaToplananGulYonetimSayfasi">
                        Toplanan Güller
                    </a>
                    <a id="gulListesi" className="menu-item" href="teslimAlinanGulMiktariSayfasi">
                        Teslim Alınan Gül Miktarı
                    </a>
                    <a id="uretilenUrunler" className="menu-item" href="uretilenUrunlerSayfasi">
                        Üretilen Ürünler
                    </a>
                    <a id="fabrikayaUlasanAraclar" className="menu-item" href="fabrikayaUlasanAraclarYonetimSayfasi">
                        Fabrikaya Ulaşan Araçlar
                    </a>
                    <a id="yoldakiAraclar" className="menu-item" href="yoldakiAraclarYonetimSayfasi">
                        Yoldaki Araçlar
                    </a>
                    <a id="uyariMesajlar" className="menu-item" href="uyariMesajlariYonetimSayfasi">
                        Uyarı Mesajları
                    </a>
                    <a id="sifreDegisimSayfasi" className="menu-item" href="sifreDegisimSayfasi">
                        Şifre Değişimi
                    </a>

                    <Link style={{ backgroundColor: "#be2828", color: "white", fontWeight: "bold", margin: 3, borderRadius: 30 }} onClick={this.handleLogout} to="/login">
                        ÇIKIŞ{" "}
                    </Link>
                </Menu>

                <Router basename={"/yonetici"}>
                    <div className="scrolling">
                        <Route path="/YoneticiAnaSayfa" component={YoneticiAnaSayfa} />
                        <Route path="/ureticiYonetimSayfasi" component={UreticiYonetimSayfasi} />
                        <Route path="/koyYonetimSayfasi" component={KoyYonetimSayfasi} />
                        <Route path="/kantarYonetimSayfasi" component={KantarYonetimSayfasi} />
                        <Route path="/kazanYonetimSayfasi" component={KazanYonetimSayfasi} />
                        <Route path="/aracYonetimSayfasi" component={AracYonetimSayfasi} />
                        <Route path="/teslimAlinanGulMiktariSayfasi" component={TeslimAlinanGulMiktariSayfasi} />
                        <Route path="/sozlesmeYonetimSayfasi" component={SozlesmeYonetimSayfasi} />
                        <Route path="/alacakVerecekDurumSayfasi" component={AlacakVerecekDurumSayfasi} />
                        <Route path="/kantardaToplananGulYonetimSayfasi" component={KantardaToplananGulYonetimSayfasi} />
                        <Route path="/yoldakiAraclarYonetimSayfasi" component={YoldakiAraclarYonetimSayfasi} />
                        <Route path="/fabrikayaUlasanAraclarYonetimSayfasi" component={FabrikayaUlasanAraclarYonetimSayfasi} />
                        <Route path="/devamEdenIslemlerYonetimSayfasi" component={DevamEdenIslemlerYonetimSayfasi} />
                        <Route path="/yoneticiBitenIslemlerSayfasi" component={YoneticiBitenIslemlerSayfasi} />
                        <Route path="/uretilenUrunlerSayfasi" component={UretilenUrunlerSayfasi} />
                        <Route path="/uyariMesajlariYonetimSayfasi" component={UyariMesajlariYonetimSayfasi} />
                        <Route path="/sifreDegisimSayfasi" component={SifreDegisimSayfasi} />
                    </div>
                </Router>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        state: state
    };
};

const mapDispatchToProps = {
    login,
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(BiSideBar);
