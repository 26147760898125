import React from "react";
import "./DevamEdenIslemlerYonetimSayfasi.css";
import "react-toastify/dist/ReactToastify.css";
import {
    getKazanListesi,
    getIslemTurListesi,
    getDevamEdenIslemlerList,
    islemiBaslatKaydet,
    islemBitisKaydet
} from "./DevamEdenIslemlerAction";
import {Icon, Table, Input, Menu} from "semantic-ui-react";
import {connect} from "react-redux";
import "react-datetime/css/react-datetime.css";
import ChangeDate from "../changeDate/ChangeDate";

class DevamEdenIslemlerYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            kazan: {
                id: ""
            },
            islemTur: {id: "", islemAd: ""},
            baslamaZaman: new Date(),
            kullanilanGulMiktar: "",
            kullanilanYagliSuMiktar: "",
            kullanilanKokuluSuMiktar: "",
            kullanilanSuMiktar: "",
            bitisZaman: "",
            uretilenYag: "",
            uretilenYagliSuMiktar: "",
            uretilenKokuluSuMiktar: "",
            uretilenKokuMiktar: "",
            filter: "",
            filtered: [],
            bdate: "",
            show: false,
            open: false,
            showBaslamaZamanEkstraksiyon: [{}],
            showBaslamaZamanRecine: [{}],
            showBaslamaZamanYagliSuDistilasyon: [{}],
            showBaslamaZamanCicekDistilasyon: [{}],
            showBaslamaZamanKohibasyon: [{}],
            showBaslamaZamanAbsolut: [{}],
        };
    }

    componentDidMount() {
        this.props.getIslemTurListesi();
        this.props.getKazanListesi();
        this.props.getDevamEdenIslemlerList();
    }

    componentWillReceiveProps(next) {

        var arr1, arr2, arr3, arr4, arr5, arr6;
        arr1 = [];
        arr2 = [];
        arr3 = [];
        arr4 = [];
        arr5 = [];
        arr6 = [];

        let i;
        for (i = 0; i < next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList.length; i++) {
            if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].baslamaZaman != null) {
                let today = next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].baslamaZaman;
                let todayNew = ChangeDate.changeDate(today);

                const data = {
                    id: next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].id,
                    date: todayNew
                }
                if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].islemTur.id == 1) {
                    arr1.push(data);
                } else if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].islemTur.id == 2) {
                    arr2.push(data);
                } else if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].islemTur.id == 3) {
                    arr3.push(data);
                } else if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].islemTur.id == 4) {
                    arr4.push(data);
                } else if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].islemTur.id == 5) {
                    arr5.push(data);
                } else if (next.state.DevamEdenIslemlerReducer.devamEdenIslemlerList[i].islemTur.id == 6) {
                    arr6.push(data);
                }
            }
        }

        this.setState({
            showBaslamaZamanEkstraksiyon: arr5,
            showBaslamaZamanRecine: arr4,
            showBaslamaZamanYagliSuDistilasyon: arr2,
            showBaslamaZamanCicekDistilasyon: arr1,
            showBaslamaZamanKohibasyon: arr3,
            showBaslamaZamanAbsolut: arr6,
        });
    }

    handleIslemTur = (event, {islemId, islemAd}) => {
        this.setState({
            islemTur: {id: islemId, islemAd: islemAd}
        });
        this.filterList(undefined, islemId);
    };

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter, islemId = this.state.islemTur.id) => {
        var updatedList = this.props.state.DevamEdenIslemlerReducer.devamEdenIslemlerList;
        updatedList = this.props.state.DevamEdenIslemlerReducer.devamEdenIslemlerList.filter(item => item.kazan.kazanAdi.toLowerCase().includes(filter) && islemId === item.islemTur.id);
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    render() {
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>DEVAM EDEN İŞLEMLER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="DevamEdenIslemler">
                    <Menu widths={8} pointing secondary color="blue">
                        {this.props.state.DevamEdenIslemlerReducer.islemList.map(post => (
                            <Menu.Item islemAd={post.value.islemAd} islemId={post.value.id}
                                       active={this.state.islemTur.id === post.value.id} onClick={this.handleIslemTur}>
                                {post.value.islemAd}
                            </Menu.Item>
                        ))}
                    </Menu>

                    {[1, 2, 3, 4, 5, 6].includes(this.state.islemTur.id) ? (
                        <div>
                            <Input name="filter" placeholder="Ara..." value={this.state.filter}
                                   onChange={this.handleFilter}/>
                            <Icon link name="times" onClick={this.clearFilter}/>

                            <Table className="table table-striped">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                        <Table.HeaderCell>KAZAN ADI</Table.HeaderCell>
                                        <Table.HeaderCell>BAŞLAMA ZAMANI</Table.HeaderCell>
                                        {[1,5].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN GÜL MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[2,3].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN YAĞLI SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[4].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN KOKULU SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[1].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[6].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN ALKOL MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[6].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN KATI YAĞ MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[5].includes(this.state.islemTur.id) ?
                                            <Table.HeaderCell>KULLANILAN HEKZAN MİKTARI (KG)</Table.HeaderCell> : ""}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.state.filtered.slice().reverse().map(post => (
                                        <Table.Row key={post.id}>
                                            <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                            <Table.Cell>{post.kazan.kazanAdi} </Table.Cell>
                                            {[1].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanCicekDistilasyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[2].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanYagliSuDistilasyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[3].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanKohibasyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[4].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanRecine[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[5].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanEkstraksiyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[6].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanAbsolut[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[1,5].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanGulMiktar}</Table.Cell> : ""}
                                            {[2,3].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanYagliSuMiktar}</Table.Cell> : ""}
                                            {[4].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanKokuluSuMiktar}</Table.Cell> : ""}
                                            {[1].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanSuMiktar}</Table.Cell> : ""}
                                            {[6].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanAlkolMiktar}</Table.Cell> : ""}
                                            {[6].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanKatiYagMiktar}</Table.Cell> : ""}
                                            {[5].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{post.kullanilanAlkolMiktar}</Table.Cell> : ""}
                                        </Table.Row>
                                    ))}
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell>
                                            <h4>TOPLAM (KG):</h4>
                                        </Table.Cell>
                                        {[1,5].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanGulMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[2,3].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanYagliSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[4].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanKokuluSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[1].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[6].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanAlkolMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[6].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanKatiYagMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[5].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{fontWeight: "bold"}}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanAlkolMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </div>
                    ) : (
                        <h3 style={{color: "red", textAlign: "center"}}>LÜTFEN İŞLEM TÜRÜNÜ SEÇİN</h3>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getKazanListesi,
    getIslemTurListesi,
    getDevamEdenIslemlerList,
    islemiBaslatKaydet,
    islemBitisKaydet
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevamEdenIslemlerYonetimSayfasi);
