import { KANTAR_LIST_GETIR, MESAJ_ACILIYET_LIST_GETIR, MESAJ_GONDERME_SUCCESS, MESAJ_GONDERME_FAIL, MESAJ_LIST_GETIR, MESAJ_DELETE_FAIL, MESAJ_DELETE_SUCCESS } from "./UyariMesajlariYonetimAction";

export default function UyariMesajlariYonetimReducer(
    state = {
        kantarList: [],
        mesajList: [],
        mesajAciliyetList: [],
        mesajGonderimSucces: {},
        gondermeDurumu: {},
        silmeDurum: {},
        fetching: true,
        error: ""
    },
    action
) {
    switch (action.type) {
        case MESAJ_LIST_GETIR:
            return {
                ...state,
                mesajList: action.payload
            };
        case KANTAR_LIST_GETIR:
            return {
                ...state,
                kantarList: action.payload
            };

        case MESAJ_ACILIYET_LIST_GETIR:
            return {
                ...state,
                mesajAciliyetList: action.payload
            };

        case MESAJ_GONDERME_SUCCESS:
            return {
                ...state,
                gondermeDurumu: action.payload
            };
        case MESAJ_GONDERME_FAIL:
            return {
                ...state,
                gondermeDurumu: action.payload
            };
        case MESAJ_DELETE_SUCCESS:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case MESAJ_DELETE_FAIL:
            return {
                ...state,
                silmeDurum: action.payload
            };
        default:
            return state;
    }
}
