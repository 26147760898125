import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';

// ReactDOM.render(<App />, document.getElementById('root'));

import { Provider } from 'react-redux';
import RootReducer from './reducer/RootReducer';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const store = createStore(
    RootReducer,
	composeWithDevTools(applyMiddleware(thunk))
	// applyMiddleware(thunk)   //for Production
);



ReactDOM.render(

	//<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	//</BrowserRouter>
	,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
