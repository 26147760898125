import axios from "axios";

import { API_PATH } from "../config/Config";

export const LIST_GETIR_URETICI = "LIST_GETIR_URETICI";
export const KOY_LIST_GETIR = "KOY_LIST_GETIR";
export const CREATE_SUCCESS_URETICI = "CREATE_SUCCESS_URETICI";
export const CREATE_FAIL_URETICI = "CREATE_FAIL_URETICI";
export const DELETE_SUCCESS_URETICI = "DELETE_SUCCESS_URETICI";
export const DELETE_FAIL_URETICI = "DELETE_FAIL_URETICI";

export function getUreticiListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/uretici/findall`)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: LIST_GETIR_URETICI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (ÜRETİCİ LİSTESİ)");
            });
    };
}
export function getKoyListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/koy/findall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post, text: post.koyAd };
                })
            )
            .then(result =>
                dispatch({
                    type: KOY_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY LİSTESİ)");
            });
    };
}

function silmeKontrolu(sonuc, ureticiBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: DELETE_SUCCESS_URETICI,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: DELETE_FAIL_URETICI,
            payload: "ERROR"
        };
    }
}

function ureticiKaydetmeKontrolu(sonuc, ureticiBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS_URETICI,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL_URETICI,
            payload: "ERROR"
        };
    }
}

export function ureticiKaydet(ureticiBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/uretici/create`, {
            method: "POST",
            data: ureticiBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(ureticiKaydetmeKontrolu(result, ureticiBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (ÜRETİCİ KAYDETME)");
            });
    };
}

export function deleteUretici(ureticiBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/uretici/delete`, {
            method: "DELETE",
            data: ureticiBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(silmeKontrolu(result, ureticiBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (ÜRETİCİ SİLME)");
            });
    };
}
