import axios from "axios";

import { API_PATH } from "../config/Config";

export const BITEN_ISLEMLER_LIST_GETIR = "BITEN_ISLEMLER_LIST_GETIR";
export const ISLEM_LIST_GETIR = "ISLEM_LIST_GETIR";

export function getBitenIslemlerListesi(bdate) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/findNotNullBitisZamani/` + bdate)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: BITEN_ISLEMLER_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (BİTEN İŞLEMLER LİSTESİ)");
            });
    };
}

export function getIslemTurListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/islemTurFindall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result =>
                dispatch({
                    type: ISLEM_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (İŞLEM TÜR LİSTESİ)");
            });
    };
}
