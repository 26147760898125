import {
    SOZLESME_LIST_GETIR,
    SOZLESME_CREATE_SUCCESS,
    SOZLESME_CREATE_FAIL,
    KOY_LIST_GETIR,
    URETICI_GETIR,
    SOZLESME_TUR_LIST,
    URETICI_FIND_SUCCESS,
    URETICI_FIND_ERROR,
    SOZLESME_DELETE_SUCCESS,
    SOZLESME_DELETE_FAIL
} from "./SozlesmeYonetimAction";

export default function SozlesmeYonetimReducer(
    state = {
        sozlesmeList: [],
        koyList: [],
        sozlesmeTurList: [],
        kayitDurumu: {},
        silmeDurum: "",
        sozlesme: {},
        uretici: {},
        error: "",
        ureticiFindStatus: ""
    },
    action
) {
    switch (action.type) {
        case SOZLESME_LIST_GETIR:
            return {
                ...state,
                sozlesmeList: action.payload
            };
        case SOZLESME_CREATE_SUCCESS:
            return {
                ...state,
                kayitDurumu: action.payload,
                ureticiFindStatus: action.ureticiStatus
            };
        case SOZLESME_CREATE_FAIL:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case KOY_LIST_GETIR:
            return {
                ...state,
                koyList: action.payload
            };
        case URETICI_GETIR:
            return {
                ...state,
                uretici: action.payload
            };
        case SOZLESME_TUR_LIST:
            return {
                ...state,
                sozlesmeTurList: action.payload
            };
        case URETICI_FIND_SUCCESS:
            return {
                ...state,
                uretici: action.payload,
                ureticiFindStatus: action.status
            };
        case URETICI_FIND_ERROR:
            return {
                ...state,
                ureticiFindStatus: action.payload
            };
        case SOZLESME_DELETE_SUCCESS:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case SOZLESME_DELETE_FAIL:
            return {
                ...state,
                silmeDurum: action.payload
            };
        default:
            return state;
    }
}
