import { KANTAR_GUL_LIST_GETIR } from "./KantardaToplananGulAction";

export default function KantardaToplananGulReducer(
    state = {
        kantarlargelenList: [],
        kantarlar: {},
        error: ""
    },
    action
) {
    switch (action.type) {
        case KANTAR_GUL_LIST_GETIR:
            return {
                ...state,
                kantarlargelenList: action.payload
            };

        default:
            return state;
    }
}
