import axios from "axios";
import moment from "moment";

import { API_PATH } from "../config/Config";

export const KANTAR_GUL_LIST_GETIR = "KANTAR_GUL_LIST_GETIR";

export function getkantarlarListesi(bdate) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kantarGul/findall/` + bdate)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: KANTAR_GUL_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KKANTARDA TOPLANAN GÜLLER LİSTESİ)");
            });
    };
}
