import React from 'react';
import './BiContainer.css';
import BiSideBar from '../bisidebar/BiSideBar'
import { connect } from 'react-redux';

const BiContainer = props => {
	return (
		<div>
			<BiSideBar />
		</div>
	)
};

const mapStateToProps = state => {
	return {
		state: state
	}
};

export default connect(mapStateToProps)(BiContainer);
