import React from "react";
import "./KantardaToplananGulYonetimSayfasi.css";
import { getkantarlarListesi } from "./KantardaToplananGulAction";
import { Icon, Table, Input, Menu, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Datetime from "react-datetime";

class Kantarlar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kantarlar: {},
            bdate: new Date(),
            show: false,
            open: false,
            filter: "",
            filtered: []
        };
        this.handleDate = this.handleDate.bind(this);
    }

    componentDidMount() {
        this.props.getkantarlarListesi(this.state.bdate.toISOString());
    }

    open = () => {
        this.setState({ open: true });
        this.props.getkantarlarListesi();
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.KantardaToplananGulReducer.kantarlargelenList
        });
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.KantardaToplananGulReducer.kantarlargelenList;
        updatedList = this.props.state.KantardaToplananGulReducer.kantarlargelenList.filter(function(item) {
            return item.kantarAd.toLowerCase().includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    handleDate = date => {
        this.setState({
            bdate: date
        });
        this.props.getkantarlarListesi(date.toISOString());
    };

    changeInModel = post => {
        this.setState({
            open: true
        });
    };

    clearState = () => {
        this.setState({
            show: false,
            open: false
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            open: false
        });
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>TOPLANAN GÜLLER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="KantarlarYonetim">
                    <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                        TARİH:
                    </Label>
                    <Input>
                        <Datetime timeFormat={false} value={this.state.bdate} defaultValue={new Date()} onChange={this.handleDate} dateFormat="DD/MM/YYYY" closeOnSelect required />
                    </Input>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>KANTAR ADI</Table.HeaderCell>
                                <Table.HeaderCell>KANTARDA BEKLEYEN GÜL MİKTARI</Table.HeaderCell>
                                <Table.HeaderCell>TOPLAM TOPLANAN GÜL MİKTARI</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.kantar.kantarAd} </Table.Cell>
                                    <Table.Cell>{post.bekleyenGulMiktari}</Table.Cell>
                                    <Table.Cell>{post.toplamGulMiktari}</Table.Cell>
                                </Table.Row>
                            ))}
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell style={{ fontWeight: "bold" }}>
                                    TOPLAM (KG): &nbsp;
                                    {this.props.state.KantardaToplananGulReducer.kantarlargelenList
                                        .map(line => line.bekleyenGulMiktari)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </Table.Cell>
                                <Table.Cell style={{ fontWeight: "bold" }}>
                                    TOPLAM (KG): &nbsp;
                                    {this.props.state.KantardaToplananGulReducer.kantarlargelenList
                                        .map(line => line.toplamGulMiktari)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getkantarlarListesi: date => dispatch(getkantarlarListesi(date))
    };
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kantarlar);
