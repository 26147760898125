import React from "react";
import "./UreticiYonetimSayfasi.css";
import "react-datetime/css/react-datetime.css";
import { getUreticiListesi, ureticiKaydet, getKoyListesi, deleteUretici } from "./UreticiYonetimAction";
import { Icon, Table, Modal, Button, Input, Form, Dropdown, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NumberFormat from "react-number-format";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const MyView = props => {
    // componenti gizlemek veya göstermek için bu componentin içine koyuyoruz.
    const { children, hide, style } = props;
    if (!hide) {
        return null;
    }
    return (
        <div {...props} style={style}>
            {children}
        </div>
    );
};

class UreticiYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            ad: "",
            soyad: "",
            tcKimlikNo: "",
            telNo: "",
            ureticiKoy: [],
            validationError: "",
            show: false,
            filter: "",
            filtered: [],
            bdate: "",
            open: false
        };
    }

    handleChangeForm = e => {
        this.setState({ ["ureticiKoy"]: { id: e.target.value } });
    };

    handleChangeC = (e, { value }) => {
        this.setState({ ["ureticiKoy"]: value });
    };

    handleChangeC = (e, { value }) => {
        this.setState({ ["ureticiKoy"]: value });
    }

    componentDidMount() {
        this.setState({
            showLoader: true
        });
        this.props.getUreticiListesi();
        this.props.getKoyListesi();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setState({
            filtered: this.props.state.UreticiYonetimReducer.ureticiList
        });
    }

    componentWillReceiveProps(next) {
        this.setState({
            showLoader: false
        });
        this.setState({
            filtered: next.state.UreticiYonetimReducer.ureticiList,
            ureticiKoy: next.state.UreticiYonetimReducer.koyList
        });

        if (next.state.UreticiYonetimReducer.kayitDurumu == "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.UreticiYonetimReducer.kayitDurumu == "ERROR") {
            toast.error("HATA! Aynı Tc Kimlik Numarası ile Kayıt Var! Lütfen Kontrol Ediniz!");
        }

        if (next.state.UreticiYonetimReducer.silmeDurum == "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.UreticiYonetimReducer.silmeDurum == "ERROR") {
            toast.error("HATA! Seçilen Üretici Bilgileri Silinemedi! İlgili Üretici Diğer Sayfalarda Kullanılıyor Olabilir !");
        }
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        let updatedList = this.props.state.UreticiYonetimReducer.ureticiList;
        updatedList = this.props.state.UreticiYonetimReducer.ureticiList.filter(function(item) {
            return item.ad.toLowerCase().includes(filter) || item.soyad.toLowerCase().includes(filter) || item.tcKimlikNo.includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    handleDate = date => {
        this.setState({ bdate: date });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeInModel = post => {
        this.setState({
            open: true,
            id: post.id,
            tcKimlikNo: post.tcKimlikNo,
            ad: post.ad,
            soyad: post.soyad,
            ureticiKoy: post.ureticiKoy,
            telNo: post.telNo
        });
    };

    deleteUreticiModel = ureticiBilgileri => {
        this.setState({ show: true, uretici: ureticiBilgileri });
    };

    deleteUretici = uretici => {
        this.props.deleteUretici(this.state.uretici);

        if (this.props.state.UreticiYonetimReducer.silmeDurum == "SUCCESS") {
            this.setState({ show: false });
        } else if (this.props.state.UreticiYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    clearState = () => {
        this.setState({
            ad: "",
            soyad: "",
            tcKimlikNo: "",
            ureticiKoy: { id: "" },
            telNo: "",
            open: false
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.ureticiKaydet(this.state);

        if (this.props.state.UreticiYonetimReducer.kayitDurumu == "SUCCESS") {
            this.setState({
                open: false
            });
        } else if (this.props.state.UreticiYonetimReducer.kayitDurumu == "ERROR") {
            this.setState({ open: true });
        }
    };

    render() {
        const { fetching } = this.props.state.UreticiYonetimReducer.ureticiList;
        return fetching ? (
            <div>Loading...</div>
        ) : (
            <div className="container">
                {" "}
                &nbsp;
                <Modal className="scrolling" size="tiny" style={{ height: 200, position: "initial" }} open={this.state.show} onClose={this.close}>
                    <Modal.Header>ÜRETİCİ SİL</Modal.Header>
                    <Modal.Content>
                        <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            HAYIR
                        </Button>
                        <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteUretici} />
                    </Modal.Actions>

                    <ToastContainer />
                </Modal>{" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>ÜRETİCİLER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="UreticiYonetim">
                    <Button onClick={this.open} color="green">
                        ÜRETİCİ EKLE
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Modal open={this.state.open} size="small" style={{ height: 800, position: "initial" }} centered={false}>
                        <Modal.Header>YENİ ÜRETİCİ EKLE</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Üretici Adı:</label>
                                        <input id="ad" name="ad" value={this.state.ad} onChange={this.handleChange} placeholder="Üretici Adı" required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Üretici Soyadı:</label>
                                        <input id="soyad" name="soyad" value={this.state.soyad} onChange={this.handleChange} placeholder="Üretici Soyadı" required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Üretici T.C. Kimlik No:</label>
                                        <NumberFormat
                                            format="###########"
                                            id="tcKimlikNo"
                                            name="tcKimlikNo"
                                            value={this.state.tcKimlikNo}
                                            onChange={this.handleChange}
                                            placeholder="Üretici T.C. Kimlik No"
                                            placeholder="***********"
                                            mask={["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"]}
                                            required
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Üretici Telefon Numarası:</label>
                                        <NumberFormat
                                            format="###########"
                                            onChange={this.handleChange}
                                            value={this.state.telNo}
                                            name="telNo"
                                            id="telNo"
                                            placeholder="***********"
                                            mask={["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"]}
                                            required
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Bulunduğu Köy:</label>
                                        <Dropdown
                                            options={this.props.state.UreticiYonetimReducer.koyList}
                                            placeholder="Lütfen Köy Seçiniz"
                                            selection
                                            fluid
                                            multiple
                                            value={this.state.ureticiKoy.id}
                                            onChange={this.handleChangeC}
                                        />
                                    </Form.Field>

                                    <Button type="submit" color="green">
                                        KAYDET
                                    </Button>
                                    <Button color="red" onClick={this.clearState}>
                                        İPTAL
                                    </Button>
                                </Form>

                                <ToastContainer />
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>

                    <Table className="table table-striped" Pagination>
                        <MyView hide={!this.state.showLoader}>
                            <Table className="table table-striped" Pagination>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                        <Table.HeaderCell>ADI SOYADI</Table.HeaderCell>
                                        <Table.HeaderCell>T.C. KİMLİK NO</Table.HeaderCell>
                                        <Table.HeaderCell>TELEFON NUMARASI</Table.HeaderCell>
                                        <Table.HeaderCell>BULUNDUĞU KÖY</Table.HeaderCell>
                                        <Table.HeaderCell>DÜZENLE</Table.HeaderCell>
                                        <Table.HeaderCell>SİL</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                   {this.state.filtered.slice().reverse().map(post => (
                                        <Table.Row key={post.id}>
                                            <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)}</Table.Cell>
                                            <Table.Cell>
                                                {post.ad} {post.soyad}
                                            </Table.Cell>
                                            <Table.Cell>{post.tcKimlikNo}</Table.Cell>
                                            <Table.Cell>{post.telNo}</Table.Cell>
                                            <Table.Cell>
                                                {post.ureticiKoy.map(koy => (
                                                    <li>{koy.koyAd}</li>
                                                ))}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button color="blue" onClick={() => this.changeInModel(post)}>
                                                    DÜZENLE
                                                </Button>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button color="red" onClick={() => this.deleteUreticiModel(post)}>
                                                    SİL
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </MyView>
                    </Table>

                    <MyView hide={this.state.showLoader} style={{ flex: 1, textAlign: "center" }}>
                        <div className="sweet-loading">
                            <ClipLoader css={override} sizeUnit={"px"} size={50} color={"#123abc"} loading={this.state.loading} />
                        </div>
                    </MyView>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getUreticiListesi,
    ureticiKaydet,
    getKoyListesi,
    deleteUretici
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UreticiYonetimSayfasi);
