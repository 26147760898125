import axios from "axios";

import { API_PATH } from "../config/Config";

export const FABRIKAYA_ULASAN_ARAC_LIST_GETIR = "FABRIKAYA_ULASAN_ARAC_LIST_GETIR";

export function getFabrikayaUlasanAraclarListesi(bdate) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/fabrikayaUlasanArac/findall/` + bdate)

            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: FABRIKAYA_ULASAN_ARAC_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (FABRİKAYA ULAŞAN ARAÇ LİSTESİ)");
            });
    };
}
