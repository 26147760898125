import React from "react";
import "./FabrikayaUlasanAraclarYonetimSayfasi.css";
import { getFabrikayaUlasanAraclarListesi } from "./FabrikayaUlasanAraclarAction";
import { Icon, Table, Input, Menu, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Datetime from "react-datetime";
import ChangeDate from "../changeDate/ChangeDate";

class FabrikayaUlasanAraclarYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fabrika: {},
            bdate: new Date(),
            filter: "",
            filtered: [],
            showUlasmaZaman: [],
        };
        this.sayfala = this.sayfala.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.sayfala();
    }

    sayfala = () => {
        this.props.getFabrikayaUlasanAraclarListesi(this.state.bdate.toISOString());
    };

    handleChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value
            },
            () => {
                this.sayfala();
            }
        );
    };

    handleDate = date => {
        this.setState(
            {
                bdate: date
            },
            () => {
                this.sayfala();
            }
        );
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi
        });

        var arr;
        arr = [];

        let i;
        for(i = 0; i < next.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi.length; i++) {
            if(next.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi[i].fabrikayaUlasmaZamani != null) {

                let today = next.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi[i].fabrikayaUlasmaZamani;
                let todayNew = ChangeDate.changeDate(today);

                const data = {
                    id: next.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi[i].aracPlaka.id,
                    date : todayNew
                }
                arr.push(data);
            }
        }

        this.setState({showUlasmaZaman: arr});
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi;
        updatedList = this.props.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi.filter(function (item) {
            return item.kantarAdi.toLowerCase().includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>FABRİKAYA ULAŞAN ARAÇLAR</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <br />
                <div className="fabrikayaUlasanAraclar">
                    <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                        TARİH:
                    </Label>
                    <Input>
                        <Datetime timeFormat={false} value={this.state.bdate} defaultValue={new Date()} onChange={this.handleDate} dateFormat="DD/MM/YYYY" closeOnSelect required />
                    </Input>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>ARAÇ PLAKASI</Table.HeaderCell>
                                <Table.HeaderCell>KANTAR ADI</Table.HeaderCell>
                                <Table.HeaderCell>FABRİKAYA ULAŞMA ZAMANI</Table.HeaderCell>
                                <Table.HeaderCell>GELEN GÜL MİKTARI (KG)</Table.HeaderCell>
                                <Table.HeaderCell>ARACA YÜKLENEN GÜL MİKTARI (KG)</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)}</Table.Cell>
                                    <Table.Cell>{post.aracPlaka.aracPlaka} </Table.Cell>
                                    <Table.Cell>{post.kantarAdi} </Table.Cell>
                                    <Table.Cell>
                                        {this.state.showUlasmaZaman[this.state.filtered.indexOf(post)].date}
                                    </Table.Cell>
                                    <Table.Cell>{post.netGulMiktari}</Table.Cell>
                                    <Table.Cell>{post.brutGulMiktari}</Table.Cell>
                                </Table.Row>
                            ))}
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>TOPLAM (KG):</th>
                                <th>
                                    {this.props.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi
                                        .map(line => line.netGulMiktari)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                                <th>
                                    {this.props.state.FabrikayaUlasanAraclarReducer.fabrikayaUlasanAraclarListesi
                                        .map(line => line.brutGulMiktari)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                            </tr>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFabrikayaUlasanAraclarListesi: date => dispatch(getFabrikayaUlasanAraclarListesi(date))
    };
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FabrikayaUlasanAraclarYonetimSayfasi);
