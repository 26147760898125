import React from "react";
import "./KazanYonetimSayfasi.css";
import { getKazanListesi, kazanKaydet, deleteKazan } from "./KazanYonetimAction";
import { Icon, Table, Modal, Button, Input, Form, Menu } from "semantic-ui-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

class KazanYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kazan: {},
            id: "",
            kazanAdi: "",
            kazanKapasitesi: "",
            filter: "",
            filtered: [],
            show: false,
            open: false
        };
    }

    componentWillMount() {
        this.props.getKazanListesi();
        this.setState({
            filtered: this.props.state.KazanYonetimReducer.kazanList
        });
    }

    componentDidMount() {
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.KazanYonetimReducer.kazanList
        });

        if (next.state.KazanYonetimReducer.kayitDurumu == "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.KazanYonetimReducer.kayitDurumu == "ERROR") {
            toast.error("HATA! Lütfen Kontrol Edip Tekrar Deneyiniz !");
        }

        if (next.state.KazanYonetimReducer.silmeDurum == "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.KazanYonetimReducer.silmeDurum == "ERROR") {
            toast.error("HATA! Seçilen araç bilgileri silinemedi! İlgili kazan diğer sayfalarda kullanılıyor olabilir !");
        }
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.KazanYonetimReducer.kazanList;
        updatedList = this.props.state.KazanYonetimReducer.kazanList.filter(function(item) {
            return item.kazanAdi.toLowerCase().includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    changeInModel = post => {
        this.setState({
            open: true,
            id: post.id,
            kazanAdi: post.kazanAdi,
            kazanKapasitesi: post.kazanKapasitesi,
            show: false
        });
    };

    deleteKazanModel = kazanBilgileri => {
        this.setState({ show: true, kazan: kazanBilgileri });
    };

    deleteKazan = kazan => {
        this.props.deleteKazan(this.state.kazan);
        if (this.props.state.KazanYonetimReducer.silmeDurum == "SUCCESS") {
            this.setState({ show: false });
        } else if (this.props.state.KazanYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    clearState = () => {
        this.setState({
            open: false
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.kazanKaydet(this.state);

        if (this.props.state.KazanYonetimReducer.kayitDurumu == "SUCCESS") {
            this.setState({
                open: false
            });
        } else if (this.props.state.KazanYonetimReducer.kayitDurumu == "ERROR") {
            this.setState({
                open: true
            });
        }
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <Modal className="scrolling" size="tiny" style={{ height: 200, position: "initial" }} open={this.state.show} onClose={this.close}>
                    <Modal.Header>Delete Your Account</Modal.Header>
                    <Modal.Content>
                        <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            HAYIR
                        </Button>
                        <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteKazan} />
                    </Modal.Actions>

                    <ToastContainer />
                </Modal>{" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>KAZANLAR</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="KazanYonetim">
                    <Button onClick={this.open} color="green">
                        KAZAN EKLE
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Modal open={this.state.open} size="small" style={{ height: 350, position: "initial" }} centered={false}>
                        <Modal.Header>YENİ KAZAN EKLE</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Kazan Adı:</label>
                                        <input id="kazanAdı" name="kazanAdi" value={this.state.kazanAdi} onChange={this.handleChange} placeholder="Kazan Adı" required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Kazan Kapasitesi (Kg):</label>
                                        <input
                                            id="KazanKapasitesi"
                                            name="kazanKapasitesi"
                                            value={this.state.kazanKapasitesi}
                                            onChange={this.handleChange}
                                            placeholder="Kazan Kapasitesi"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            required
                                        />
                                    </Form.Field>

                                    <Button type="submit" color="green">
                                        KAYDET
                                    </Button>
                                    <Button color="red" onClick={this.clearState}>
                                        İPTAL
                                    </Button>

                                    <ToastContainer />
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>KAZAN ADI</Table.HeaderCell>
                                <Table.HeaderCell>KAZAN KAPASİTESİ (KG)</Table.HeaderCell>
                                <Table.HeaderCell>DÜZENLE</Table.HeaderCell>
                                <Table.HeaderCell>SİL</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.kazanAdi}</Table.Cell>
                                    <Table.Cell>{post.kazanKapasitesi}</Table.Cell>
                                    <Table.Cell>
                                        <Button color="blue" onClick={() => this.changeInModel(post)}>
                                            DÜZENLE
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button color="red" onClick={() => this.deleteKazanModel(post)}>
                                            SİL
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getKazanListesi,
    kazanKaydet,
    deleteKazan
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KazanYonetimSayfasi);
