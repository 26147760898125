import { FABRIKAYA_ULASAN_ARAC_LIST_GETIR } from "./FabrikayaUlasanAraclarAction";

export default function(
    state = {
        fabrikayaUlasanAraclarListesi: []
    },
    action
) {
    switch (action.type) {
        case FABRIKAYA_ULASAN_ARAC_LIST_GETIR:
            return {
                ...state,
                fabrikayaUlasanAraclarListesi: action.payload
            };
        default:
            return state;
    }
}
