import axios from "axios";

import { API_PATH } from "../config/Config";

export const KANTAR_LIST_GETIR = "KANTAR_LIST_GETIR";
export const MESAJ_ACILIYET_LIST_GETIR = "MESAJ_ACILIYET_LIST_GETIR";
export const MESAJ_GONDERME_SUCCESS = "MESAJ_GONDERME_SUCCESS";
export const MESAJ_GONDERME_FAIL = "MESAJ_GONDERME_FAIL";
export const MESAJ_LIST_GETIR = "MESAJ_LIST_GETIR";
export const MESAJ_DELETE_SUCCESS = "MESAJ_DELETE_SUCCESS";
export const MESAJ_DELETE_FAIL = "MESAJ_DELETE_FAIL";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const DELETE_FAIL = "DELETE_FAIL";

export function getMesajListesi(kullaniciId) {
    const hash = sessionStorage.getItem("sessionToken");

    return dispatch => {
        axios
            .get(`${API_PATH}/api/mesaj/findall`)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: MESAJ_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (MESAJ LİSTESİ)");
            });
    };
}

export function getKantarListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kantar/findall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: "", display: "(Lütfen kantar bilgisini seçiniz)" }].concat(result))
            .then(result =>
                dispatch({
                    type: KANTAR_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KANTAR LİSTESİ)");
            });
    };
}

export function getMesajAciliyetListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/mesaj/mesajAciliyetFindAll`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: "", display: "(Lütfen kantar bilgisini seçiniz)" }].concat(result))
            .then(result =>
                dispatch({
                    type: MESAJ_ACILIYET_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (MESAJ ACİLİYET LİSTESİ)");
            });
    };
}

function mesajGondermeKontrolu(sonuc, mesajDetay) {
    if (sonuc.status === 200) {
        return {
            type: MESAJ_GONDERME_SUCCESS,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: MESAJ_GONDERME_FAIL,
            payload: "ERROR"
        };
    }
}

export function mesajGonder(mesajDetay) {
    mesajDetay.filtered = [];
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/mesaj/create`, {
            method: "POST",
            data: mesajDetay,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(mesajGondermeKontrolu(result, mesajDetay)))
            .catch(() => {
                alert("SİSTEM HATASI! (MESAJ KAYDETME)");
            });
    };
}

function silmeKontrolu(sonuc, mesajBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: MESAJ_DELETE_SUCCESS,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: MESAJ_DELETE_FAIL,
            payload: "ERROR"
        };
    }
}

export function deleteMesaj(mesajBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/mesaj/delete`, {
            method: "DELETE",
            data: mesajBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(silmeKontrolu(result, mesajBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (MESAJ SİLME)");
            });
    };
}
