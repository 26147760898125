import React from "react";
import "./SozlesmeYonetimSayfasi.css";
import { getKoyListesi, getSozlesmeListesi, sozlesmeKaydet, deleteSozlesme, findUretici, sozlesmeTurList } from "./SozlesmeYonetimAction";
import { Icon, Table, Modal, Button, Input, Form, Menu, Label, Dropdown } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import Datetime from "react-datetime";

class SozlesmeYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sozlesme: {},
            ureticiTcKimlikNo: "",
            tcKimlikNo: "",
            uretici: { id: "", tcKimlikNo: "" },
            sozlesmeTur: { id: "" },
            avansMiktari: "",
            tahminiGulMiktari: "",
            tarih: new Date(),
            aktif: Boolean,
            ad: "",
            soyad: "",
            bulunduguKoyId: "Tüm Köyler",
            filter: "",
            filtered: [],
            sozlesmeDurumList: [{ text: " " }, { value: true, text: "Aktif" }, { value: false, text: "Pasif" }]
        };
    }

    submit = () => {
        this.props.getSozlesmeListesi(this.state.tarih.toISOString(), this.state.bulunduguKoyId);
    };

    ureticiBul = () => {
        this.props.findUretici(this.state.ureticiTcKimlikNo);
    };

    handleDate = date => {
        this.setState({ tarih: date._d });
    };

    componentWillReceiveProps(next) {
        this.setState({
            tcKimlikNo: next.state.SozlesmeYonetimReducer.uretici.tcKimlikNo,
            uretici: next.state.SozlesmeYonetimReducer.uretici,
            filtered: next.state.SozlesmeYonetimReducer.sozlesmeList
        });

        if (next.state.SozlesmeYonetimReducer.ureticiFindStatus === "SUCCESS") {
            toast.success("Aranılan üretici bulundu !");
            this.setState({
                ad: next.state.SozlesmeYonetimReducer.uretici.ad,
                soyad: next.state.SozlesmeYonetimReducer.uretici.soyad
            });
        } else if (next.state.SozlesmeYonetimReducer.ureticiFindStatus === "ERROR") {
            toast.error("HATA! Aranılan Üretici Bulunamadı!");
            this.setState({
                ad: "",
                soyad: ""
            });
        }

        if (next.state.SozlesmeYonetimReducer.kayitDurumu == "SUCCESS") {
            toast.success("Kayıt işlemi Başarılı");
            window.location.reload();
        } else if (next.state.SozlesmeYonetimReducer.kayitDurumu == "ERROR") {
            toast.error("Hata! Kayıt işlemi tamamlanamadı!");
        }

        if (next.state.SozlesmeYonetimReducer.silmeDurum === "SUCCESS") {
            window.location.reload();
            toast.success("Kayıt Silinmiştir !");
        } else if (next.state.SozlesmeYonetimReducer.silmeDurum === "ERROR") {
            toast.error("HATA! Seçilen sözleşme bilgileri silinemedi! ");
        }
    }

    handleChangeForm = (e, { value }) => {
        this.setState({ bulunduguKoyId: value });
    };

    handleChangeSozlesmeDurum = e => {
        this.setState({ aktif: e.target.value });
    };

    handleChangeSozlesmeTur = e => {
        this.setState({ ["sozlesmeTur"]: { id: e.target.value } });
    };

    componentDidMount() {
        this.props.getSozlesmeListesi(this.state.tarih.toISOString(), this.state.bulunduguKoyId);
        this.props.getKoyListesi();
        this.props.sozlesmeTurList();
    }

    componentWillMount() {
        this.setState({
            filtered: this.props.state.KoyYonetimReducer.koygelenList
        });
    }

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.SozlesmeYonetimReducer.sozlesmeList;
        updatedList = this.props.state.SozlesmeYonetimReducer.sozlesmeList.filter(function(item) {
            return (
                item.uretici.ad.toLowerCase().includes(filter) ||
                item.uretici.soyad.toLowerCase().includes(filter) ||
                item.uretici.tcKimlikNo.toLowerCase().includes(filter) ||
                item.uretici.ureticiKoy.koyAd.toLowerCase().includes(filter) ||
                item.sozlesmeTur.sozlesmeTurAd.toLowerCase().includes(filter) ||
                item.tarih.toLowerCase().includes(filter)
            );
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeInModel = post => {
        this.props.findUretici(post.uretici.tcKimlikNo);
        this.setState({
            open: true,
            id: post.id,
            ureticiTcKimlikNo: post.uretici.tcKimlikNo,
            sozlesmeTur: { id: "" },
            ["sozlesmeTur"]: { id: post.sozlesmeTur.id },
            avansMiktari: post.avansMiktari,
            tahminiGulMiktari: post.tahminiGulMiktari,
            aktif: post.aktif
        });
    };

    deleteSozlesmeModel = sozlesmeBilgileri => {
        this.setState({
            show: true,
            sozlesme: sozlesmeBilgileri
        });
    };

    deleteSozlesme = sozlesme => {
        this.props.deleteSozlesme(this.state.sozlesme);

        if (this.props.state.SozlesmeYonetimReducer.silmeDurum == "SUCCESS") {
            this.setState({ show: false });
        } else if (this.props.state.SozlesmeYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    clearState = () => {
        this.setState({
            show: false,
            open: false,
            sozlesme: {},
            ureticiTcKimlikNo: "",
            tcKimlikNo: "",
            uretici: { id: "", tcKimlikNo: "" },
            sozlesmeTur: { id: "" },
            avansMiktari: "",
            tahminiGulMiktari: "",
            tarih: new Date(),
            aktif: Boolean,
            ad: "",
            soyad: "",
            bulunduguKoyId: "Tüm Köyler",
            sozlesmeDurumList: [{ text: " " }, { value: true, text: "Aktif" }, { value: false, text: "Pasif" }]
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.sozlesmeKaydet(this.state);
        this.clearState();

        if (this.props.state.SozlesmeYonetimReducer.kayitDurumu == "SUCCESS") {
            this.setState({
                open: false
            });
            this.clearState();
        } else if (this.props.state.SozlesmeYonetimReducer.kayitDurumu == "ERROR") {
            this.setState({
                open: true
            });
        }
    };

    render() {
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <Modal className="scrolling" size="tiny" style={{ height: 200, position: "initial" }} open={this.state.show} onClose={this.close}>
                    <Modal.Header>SÖZLEŞME SİL</Modal.Header>
                    <Modal.Content>
                        <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            HAYIR
                        </Button>
                        <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteSozlesme} />
                    </Modal.Actions>
                </Modal>{" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>SÖZLEŞMELER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="SozlesmeYonetim">
                    <div>
                        <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                            TARİH:
                        </Label>
                        <Input>
                            <Datetime timeFormat={false} value={this.state.tarih} defaultValue={new Date()} onChange={this.handleDate} dateFormat="YYYY" closeOnSelect required />
                        </Input>

                        <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                            KÖY:
                        </Label>
                        <Dropdown
                            placeholder="Köy seçin."
                            required
                            style={{ margin: 5 }}
                            selection
                            defaultValue={"Tüm Köyler"}
                            value={this.state.bulunduguKoyID}
                            onChange={this.handleChangeForm}
                            options={this.props.state.SozlesmeYonetimReducer.koyList.map(team => ({ key: team.value.id, value: team.value.id, text: team.value.koyAd }))}
                        />

                        <Button style={{ margin: 5 }} onClick={this.submit} color="blue">
                            BUL
                        </Button>
                        <Button onClick={this.open} color="green">
                            YENİ SÖZLEŞME
                        </Button>
                        <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                        <Icon link name="times" onClick={this.clearFilter} />
                    </div>

                    <div className="SozlesmeYonet">
                        <Modal open={this.state.open} size="tiny" style={{ height: 900, position: "initial" }} centered={false}>
                            <Modal.Header>YENİ SÖZLEŞME EKLE</Modal.Header>
                            <Modal.Content>
                                <Modal.Description>
                                    <Form>
                                        <label style={{ fontSize: 18, fontWeight: "bold" }}>
                                            Üretici T.C. Kimlik No:
                                            <NumberFormat
                                                required
                                                format="###########"
                                                id="ureticiTcKimlikNo"
                                                name="ureticiTcKimlikNo"
                                                value={this.state.ureticiTcKimlikNo}
                                                onChange={this.handleChange}
                                                placeholder="***********"
                                                mask={["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"]}
                                            />
                                            <Button style={{ margin: 5 }} color="blue" onClick={this.ureticiBul}>
                                                ÜRETİCİ BUL
                                            </Button>
                                        </label>
                                    </Form>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Üretici Adı:</label>
                                            <input
                                                required
                                                id="ureticiAdSoyad"
                                                name="ureticiAdSoyad"
                                                value={this.state.ad}
                                                onChange={this.handleChange}
                                                placeholder="Üretici Adı"
                                                contentEditable={false}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Üretici Soyadı:</label>
                                            <input
                                                id="ureticiAdSoyad"
                                                name="ureticiAdSoyad"
                                                value={this.state.soyad}
                                                onChange={this.handleChange}
                                                placeholder="Üretici Soyadı"
                                                contentEditable={false}
                                                required
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Sözleşme Türü:</label>
                                            <select required value={this.state.sozlesmeTur.id} onChange={this.handleChangeSozlesmeTur} required>
                                                {" "}
                                                {this.props.state.SozlesmeYonetimReducer.sozlesmeTurList.map(team => (
                                                    <option key={team.value.id} value={team.value.id}>
                                                        {team.value.sozlesmeTurAd}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Sözleşme Tarihi:</label>
                                            <input value={this.state.tarih} defaultValue={new Date()} dateFormat="YYYY" required contentEditable={false} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Avans Miktarı:</label>
                                            <input id="avansMiktari" name="avansMiktari" value={this.state.avansMiktari} onChange={this.handleChange} required placeholder="Avans Miktarı" />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Tahmini Gül Miktarı (Kg):</label>
                                            <input
                                                id="tahminiGulMiktari"
                                                name="tahminiGulMiktari"
                                                value={this.state.tahminiGulMiktari}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Tahmini Gül Miktarı"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={{ fontSize: 18 }}>Sözleşme Durumu:</label>
                                            <select value={this.state.aktif} onChange={this.handleChangeSozlesmeDurum} required>
                                                {" "}
                                                {this.state.sozlesmeDurumList.map(team => (
                                                    <option key={team.value} value={team.value}>
                                                        {team.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Field>

                                        <Button type="submit" color="green">
                                            KAYDET
                                        </Button>
                                        <Button color="red" onClick={this.clearState}>
                                            İPTAL
                                        </Button>
                                    </Form>
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>

                        <Table className="table table-striped">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                    <Table.HeaderCell>ÜRETİCİ ADI SOYADI</Table.HeaderCell>
                                    <Table.HeaderCell>ÜRETİCİ T.C. KİMLİK NO</Table.HeaderCell>
                                    <Table.HeaderCell>BULUNDUĞU KÖY</Table.HeaderCell>
                                    <Table.HeaderCell>SÖZLEŞME TÜRÜ </Table.HeaderCell>
                                    <Table.HeaderCell>AVANS MİKTARI (TL)</Table.HeaderCell>
                                    <Table.HeaderCell>TAHMİNİ GÜL MİKTARI (KG)</Table.HeaderCell>
                                    <Table.HeaderCell>SÖZLEŞME AKTİFLİK DURUMU</Table.HeaderCell>
                                    <Table.HeaderCell>DÜZENLE</Table.HeaderCell>
                                    <Table.HeaderCell>SİL</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                               {this.state.filtered.slice().reverse().map(post => (
                                    <Table.Row key={post.id}>
                                        <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                        <Table.Cell>
                                            {post.uretici.ad} {post.uretici.soyad}
                                        </Table.Cell>
                                        <Table.Cell>{post.uretici.tcKimlikNo}</Table.Cell>
                                        <Table.Cell>
                                            {post.uretici.ureticiKoy.map(koy => (
                                                <li>{koy.koyAd}</li>
                                            ))}
                                        </Table.Cell>
                                        <Table.Cell>{post.sozlesmeTur.sozlesmeTurAd}</Table.Cell>
                                        <Table.Cell>{post.avansMiktari}</Table.Cell>
                                        <Table.Cell>{post.tahminiGulMiktari}</Table.Cell>
                                        <Table.Cell>{post.aktif ? "Aktif" : "Pasif"}</Table.Cell>
                                        <Table.Cell>
                                            <Button color="blue" onClick={() => this.changeInModel(post)}>
                                                DÜZENLE
                                            </Button>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button color="red" onClick={() => this.deleteSozlesmeModel(post)}>
                                                SİL
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>

                            <ToastContainer />
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    sozlesmeKaydet,
    getKoyListesi,
    deleteSozlesme,
    findUretici,
    getSozlesmeListesi,
    sozlesmeTurList
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SozlesmeYonetimSayfasi);
