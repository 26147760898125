import axios from "axios";

import { API_PATH } from "../config/Config";

export const LIST_GETIR_KANTAR = "LIST_GETIR_KANTAR";
export const CREATE_SUCCESS_KANTAR = "CREATE_SUCCESS_KANTAR";
export const CREATE_FAIL_KANTAR = "CREATE_FAIL_KANTAR";
export const DELETE_SUCCESS_KANTAR = "DELETE_SUCCESS_KANTAR";
export const DELETE_FAIL_KANTAR = "DELETE_FAIL_KANTAR";
export const DELETE = "DELETE";
export const KOY_LIST_GETIR = "KOY_LIST_GETIR";

export function getKantarListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kantar/findall`)

            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: LIST_GETIR_KANTAR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KANTAR LİSTESİ)");
            });
    };
}

export function getKoyListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/koy/findall`)
            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post, text: post.koyAd };
                })
            )
            .then(result =>
                dispatch({
                    type: KOY_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY LİSTESİ)");
            });
    };
}

function kantarKaydetmeKontrolu(sonuc, kantarBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS_KANTAR,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL_KANTAR,
            payload: "ERROR"
        };
    }
}

export function kantarKaydet(kantarBilgileri) {
    kantarBilgileri.filtered = [];

    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/kantar/create`, {
            method: "POST",
            data: kantarBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(kantarKaydetmeKontrolu(result, kantarBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (KANTAR KAYDETME)");
            });
    };
}

function silmeKontrolu(sonuc, kantarBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: DELETE_SUCCESS_KANTAR,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: DELETE_FAIL_KANTAR,
            payload: "ERROR"
        };
    }
}

export function deleteKantar(kantarBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/kantar/delete`, {
            method: "DELETE",
            data: kantarBilgileri,

            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(silmeKontrolu(result, kantarBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (KANTAR SİLME)");
            });
    };
}
