import axios from "axios";

import { API_PATH } from "../config/Config";

export const SUCCESS_SIFRE_DEGISIMI = "SUCCESS_SIFRE_DEGISIMI";
export const FAIL_SIFRE_DEGISIMI = "FAIL_SIFRE_DEGISIMI";
export const FAIL_SIFRE_UYUMU = "FAIL_SIFRE_UYUMU";
export const SUCCESS_SIFRE_UYUMU = "SUCCESS_SIFRE_UYUMU";

function sifreDegisimKontrol(hash, veri) {
    if (veri.status === 200 && veri.data.role === "YONETICI") {
        sessionStorage.getItem("sessionToken", hash);
        sessionStorage.getItem("kullaniciId", veri.data.id);
        sessionStorage.getItem("kullaniciRolu", veri.data.role);
        sessionStorage.getItem("kullaniciTcKimlikNo", veri.data.username);

        return {
            type: SUCCESS_SIFRE_DEGISIMI,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: FAIL_SIFRE_DEGISIMI,
            payload: "ERROR"
        };
    }
}

export function sifreDegisimi(sifreBilgileri) {
    if (sifreBilgileri.yeniSifre === sifreBilgileri.yeniSifreTekrar) {
        return dispatch => {
            dispatch({
                type: SUCCESS_SIFRE_UYUMU,
                payload: "SUCCESS"
            });
            const hash = sessionStorage.getItem("sessionToken");

            axios
                .post(`${API_PATH}/api/user/sifreDegisim/` + sifreBilgileri.yeniSifre + `/` + sifreBilgileri.eskiSifre + `/` + sifreBilgileri.kullaniciTcKimlikNo, {
                    headers: { Authorization: `Basic ${hash}` }
                })
                .then(result => dispatch(sifreDegisimKontrol(hash, result)))
                .catch(() => {
                    alert("SİSTEM HATASI! (ŞİFRE DEĞİŞTİRME)");
                });
        };
    } else {
        return dispatch => {
            dispatch({
                type: FAIL_SIFRE_UYUMU,
                payload: "ERROR"
            });
        };
    }
}
