import React from "react";
import "./SifreDegisimSayfasi.css";
import { sifreDegisimi } from "./SifreDegisimAction";
import { Button, Form, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "../../../node_modules/react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SifreDegisimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eskiSifre: "",
            yeniSifre: "",
            yeniSifreTekrar: "",
            kullaniciTcKimlikNo: ""
        };
    }

    componentDidMount() {
        this.setState({
            kullaniciTcKimlikNo: sessionStorage.getItem("kullaniciTcKimlikNo")
        });
    }

    clearState = () => {
        this.setState({
            eskiSifre: "",
            yeniSifre: "",
            yeniSifreTekrar: ""
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentWillReceiveProps(next) {
        if (this.props.state.SifreDegisimReducer.sifreDegisimiOnayDurumu === "SUCCESS") {
            toast.success("Şifre başarıyla değiştirildi !");
            window.location.reload();
        } else if (this.props.state.SifreDegisimReducer.sifreDegisimiOnayDurumu === "ERROR") {
            toast.error("HATA! Eski Şifre yanlış girildi! Lütfen Kontrol Ediniz!");
        }

        if (this.props.state.SifreDegisimReducer.sifreEslesmeDurumu === "ERROR") {
            toast.error("HATA ! Yeni Şifre Tekrarı ile Uyuşmuyor !");
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.sifreDegisimi(this.state);
        window.location.reload();

        if (this.props.state.SifreDegisimReducer.sifreDegisimiOnayDurumu === "SUCCESS") {
            toast.success("Şifre başarı ile değiştirilmiştir !");
            window.location.reload();
        } else if (this.props.state.SifreDegisimReducer.sifreDegisimiOnayDurumu === "ERROR") {
            toast.error("HATA! Şifre değişimi başarısız ! Lütfen tekrar deneyiniz !");
        }
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>ŞİFRE DEĞİŞİM</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="SifreDegisim">
                    <ToastContainer />
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <label style={{ fontSize: 18 }}>Eski Şifre:</label>
                            <input
                                disabled={this.state.disabled}
                                id="eskiSifre"
                                name="eskiSifre"
                                value={this.state.eskiSifre}
                                onChange={this.handleChange}
                                type="password"
                                placeholder="Eski Sifre"
                                required
                            />
                        </Form.Field>
                        <Form.Field>
                            <label style={{ fontSize: 18 }}>Yeni Şifre:</label>{" "}
                            <input
                                disabled={this.state.disabled}
                                id="yeniSifre"
                                name="yeniSifre"
                                value={this.state.yeniSifre}
                                onChange={this.handleChange}
                                type="password"
                                placeholder="Yeni Şifre"
                                required
                            />
                        </Form.Field>
                        <Form.Field>
                            <label style={{ fontSize: 18 }}>Yeni Şifre Tekrar:</label>
                            <input
                                disabled={this.state.disabled}
                                id="yeniSifreTekrar"
                                name="yeniSifreTekrar"
                                value={this.state.yeniSifreTekrar}
                                onChange={this.handleChange}
                                type="password"
                                placeholder="Yeni Şifre Tekrar"
                                required
                            />
                        </Form.Field>

                        <Button type="submit" color="green">
                            KAYDET
                        </Button>
                        <Button color="red" onClick={this.clearState}>
                            İPTAL
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    sifreDegisimi
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SifreDegisimSayfasi);
