import React from "react";
import "./KantarYonetimSayfasi.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getKantarListesi, kantarKaydet, deleteKantar, getKoyListesi } from "./KantarYonetimAction";
import { Icon, Table, Modal, Button, Input, Form, Dropdown, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

class KantarYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kantar: {},
            id: "",
            kantarAd: "",
            kantarMemurAd: "",
            kantarMemurSoyad: "",
            telNo: "",
            sorumluKoyler: [],
            kantarKoyId: "",
            kantarMemurTcKimlikNo: "",
            show: false,
            open: false,
            filter: "",
            filtered: [],
            sorumluKoyler: []
        };
    }

    componentDidMount() {
        this.props.getKantarListesi();
        this.props.getKoyListesi();
    }

    componentWillMount() {
        this.setState({
            filtered: this.props.state.KantarYonetimReducer.kantargelenList
        });
    }

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.KantarYonetimReducer.kantargelenList
        });

        if (next.state.KantarYonetimReducer.kayitDurumu === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.KantarYonetimReducer.kayitDurumu === "ERROR") {
            toast.error("HATA! Tekrar Eden Tc Kimlik No Girildi! Lütfen Kontrol Ediniz!");
        }

        if (next.state.KantarYonetimReducer.silmeDurum === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.KantarYonetimReducer.silmeDurum === "ERROR") {
            toast.error("HATA! Seçilen kantar bilgileri silinemedi! Lütfen Yetkili ile İletişimi Geçiniz!!");
        }
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.KantarYonetimReducer.kantargelenList;
        updatedList = this.props.state.KantarYonetimReducer.kantargelenList.filter(function(item) {
            return (
                item.kantarAd.toLowerCase().includes(filter) ||
                item.kantarMemurAd.toLowerCase().includes(filter) ||
                item.kantarMemurSoyad.toLowerCase().includes(filter) ||
                item.kantarMemurTcKimlikNo.toLowerCase().includes(filter)
            );
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeInModel = post => {
        this.setState({
            open: true,
            id: post.id,
            kantarAd: post.kantarAd,
            kantarMemurAd: post.kantarMemurAd,
            kantarMemurSoyad: post.kantarMemurSoyad,
            kantarMemurTcKimlikNo: post.kantarMemurTcKimlikNo,
            telNo: post.telNo,
            sorumluKoyler: post.sorumluKoyler,
            kantarKoyId: post.kantarKoyId
        });
    };

    deleteKantarModel = kantarBilgileri => {
        this.setState({
            show: true,
            kantar: kantarBilgileri
        });
    };

    deleteKantar = kantar => {
        this.props.deleteKantar(this.state.kantar);

        if (this.props.state.KantarYonetimReducer.silmeDurum == "SUCCESS") {
            toast.success("Success Notification !");
            this.setState({ show: false });
        } else if (this.props.state.KantarYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    clearState = () => {
        this.setState({
            id: "",
            kantarAd: "",
            kantarMemurAd: "",
            kantarMemurSoyad: "",
            kantarMemurTcKimlikNo: "",
            telNo: "",
            sorumluKoyler: [],
            kantarKoyId: "",
            show: false,
            open: false
        });
    };

    change = event => {
        this.setState({ value: event.target.value });
    };

    handleChangeForm = e => {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.kantarKaydet(this.state);

        if (this.props.state.KantarYonetimReducer.kayitDurumu == "SUCCESS") {
            this.setState({
                open: false
            });
        } else if (this.props.state.KantarYonetimReducer.kayitDurumu == "ERROR") {
            this.setState({
                open: true
            });
        }
    };

    handleChangeC = (e, { value }) => {
        this.setState({ sorumluKoyler: value });
    };

    render() {
        const { sorumluKoyler } = this.state;
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <Modal className="scrolling" size="tiny" style={{ height: 200, position: "initial" }} open={this.state.show} onClose={this.close}>
                    <Modal.Header>KANTAR SİL</Modal.Header>
                    <Modal.Content>
                        <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            HAYIR
                        </Button>
                        <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteKantar} />
                    </Modal.Actions>
                    <ToastContainer />
                </Modal>
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>KANTARLAR</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="KantarYonetim">
                    <Button onClick={this.open} color="green">
                        KANTAR EKLE
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Modal open={this.state.open} size="small" style={{ height: 900, position: "initial" }} centered={false}>
                        <Modal.Header>YENİ KANTAR EKLE</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Kantar Adı:</label>
                                        <input id="kantarAd" name="kantarAd" value={this.state.kantarAd} onChange={this.handleChange} placeholder="Kantar Adı" required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Kantar Memuru T.C. Kimlik No:</label>
                                        <NumberFormat
                                            format="###########"
                                            id="kantarMemurTcKimlikNo"
                                            name="kantarMemurTcKimlikNo"
                                            value={this.state.kantarMemurTcKimlikNo}
                                            onChange={this.handleChange}
                                            placeholder="Kantar Memuru T.C. Kimlik No"
                                            placeholder="***********"
                                            mask={["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"]}
                                            required
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Kantar Memuru Adı:</label>
                                        <input id="kantarMemurAd" name="kantarMemurAd" value={this.state.kantarMemurAd} onChange={this.handleChange} placeholder="Kantar Memuru Adı" required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Kantar Memuru Soyadı:</label>
                                        <input
                                            id="kantarMemurSoyad"
                                            name="kantarMemurSoyad"
                                            value={this.state.kantarMemurSoyad}
                                            onChange={this.handleChange}
                                            placeholder="Kantar Memuru Soyadı"
                                            required
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Telefon Numarası:</label>
                                        <NumberFormat
                                            format="###########"
                                            onChange={this.handleChange}
                                            value={this.state.telNo}
                                            name="telNo"
                                            id="telNo"
                                            placeholder="***********"
                                            mask={["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"]}
                                            required
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Sorumlu Köyler:</label>
                                        <Dropdown
                                            options={this.props.state.KantarYonetimReducer.koyList}
                                            placeholder="Lütfen Köy Seçiniz"
                                            selection
                                            fluid
                                            multiple
                                            value={sorumluKoyler}
                                            onChange={this.handleChangeC}
                                        />
                                    </Form.Field>

                                    <Button type="submit" color="green">
                                        KAYDET
                                    </Button>
                                    <Button color="red" onClick={this.clearState}>
                                        İPTAL
                                    </Button>

                                    <ToastContainer />
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>KANTAR ADI</Table.HeaderCell>
                                <Table.HeaderCell>KANTAR MEMURU ADI SOYADI</Table.HeaderCell>
                                <Table.HeaderCell>T.C. KİMLİK NO</Table.HeaderCell>
                                <Table.HeaderCell>TELEFON</Table.HeaderCell>
                                <Table.HeaderCell>SORUMLU KÖYLER</Table.HeaderCell>
                                <Table.HeaderCell>DÜZENLE</Table.HeaderCell>
                                <Table.HeaderCell>SİL</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.kantarAd} </Table.Cell>
                                    <Table.Cell>
                                        {post.kantarMemurAd} {post.kantarMemurSoyad}
                                    </Table.Cell>
                                    <Table.Cell>{post.kantarMemurTcKimlikNo}</Table.Cell>
                                    <Table.Cell>{post.telNo} </Table.Cell>
                                    <Table.Cell>
                                        {post.sorumluKoyler.map(koy => (
                                            <li>{koy.koyAd}</li>
                                        ))}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button color="blue" onClick={() => this.changeInModel(post)}>
                                            DÜZENLE
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button color="red" onClick={() => this.deleteKantarModel(post)}>
                                            SİL
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getKoyListesi,
    getKantarListesi,
    kantarKaydet,
    deleteKantar
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KantarYonetimSayfasi);
