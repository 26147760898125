import { YOLDAKI_ARAC_LIST_GETIR } from "./YoldakiAraclarYonetimAction";
export default function ureticiYonetimReducer(
    state = {
        yoldakiAracList: [],
        fetching: true,
        teslimIslemi: {},
        error: ""
    },
    action
) {
    switch (action.type) {
        case YOLDAKI_ARAC_LIST_GETIR:
            return {
                ...state,
                yoldakiAracList: action.payload
            };
        default:
            return state;
    }
}
