import axios from "axios";

import { API_PATH } from "../config/Config";

export const YOLDAKI_ARAC_LIST_GETIR = "YOLDAKI_ARAC_LIST_GETIR";

export function getYoldakiAracListesi(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/aractasima/findNotNullFabrikayaTeslimEtme/` + date)

            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: YOLDAKI_ARAC_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (YOLDAKİ ARAÇLAR LİSTESİ)");
            });
    };
}
