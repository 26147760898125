import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./UserAction";

export default function user(
    state = {
        isLoggingIn: false,
        isAuthenticated: false,
        token: "",
        kullaniciRole: "",
        kullaniciId: "",
        kullaniciTcKimlikNo: ""
    },
    action
) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                isAuthenticated: true,
                token: action.payload
            };
        case LOGIN_FAILURE:
            return {
                isAuthenticated: false,
                error: action.payload
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                kullaniciId: action.payload.id,
                kullaniciTcKimlikNo: action.payload.username,
                kullaniciRole: action.payload.role
            };
        case LOGOUT:
            return {
                isAuthenticated: false
            };
        default:
            return state;
    }
}
