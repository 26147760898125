import axios from "axios";
import { API_PATH } from "../config/Config";

export const TESLIM_ALINAN_GUL_MIKTAR = "TESLIM_ALINAN_GUL_MIKTAR";
export const URETILEN_YAG_MIKTAR = "URETILEN_YAG_MIKTAR";
export const TASINAN_VE_BEKLEYEN_GUL_MIKTAR = "TASINAN_VE_BEKLEYEN_GUL_MIKTAR";
export const YONETICI_ANA_SAYFA_KANTAR_TOPLAM_GUL_MIKTARI = "YONETICI_ANA_SAYFA_KANTAR_TOPLAM_GUL_MIKTARI";
export const YONETICI_YOLDAKI_GUL_MIKTARI = "YONETICI_YOLDAKI_GUL_MIKTARI";
export const YONETICI_FABRIKADAKI_GUL_MIKTARI = "YONETICI_FABRIKADAKI_GUL_MIKTARI";
export const YONETICI_KULLANILAN_GUL_MIKTARI = "YONETICI_KULLANILAN_GUL_MIKTARI";
export const YONETICI_KAZAN_KULLANILAN_GUL_MIKTARI = "YONETICI_KAZAN_KULLANILAN_GUL_MIKTARI";

export function teslimAlinanGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/ureticiteslim/ureticiTeslimTarihFilter/` + date)
            .then(result => result.data)
            .then(result => result.map(line => line.miktar).reduce((prev, next) => prev + next, 0))
            .then(result =>
                dispatch({
                    type: TESLIM_ALINAN_GUL_MIKTAR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (TESLİM ALINAN GÜL MİKTARI)");
            });
    };
}

export function uretilenYagMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/findNotNullBitisZamani/` + date)
            .then(result => result.data)
            .then(result => result.map(line => line.uretilenYag).reduce((prev, next) => prev + next, 0))
            .then(result =>
                dispatch({
                    type: URETILEN_YAG_MIKTAR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (ÜRETİLEN YAĞ MİKTARI)");
            });
    };
}

export function tasinanVeBekleyenGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kantarGul/findall/` + date)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: TASINAN_VE_BEKLEYEN_GUL_MIKTAR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (BEKLEYEN GÜL MİKTARI)");
            });
    };
}

export function yoldakiGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/aractasima/findNotNullFabrikayaTeslimEtme/` + date)
            .then(result => result.data)
            .then(result => result.map(line => line.kantardakiAgirlik).reduce((prev, next) => prev + next, 0))
            .then(result =>
                dispatch({
                    type: YONETICI_YOLDAKI_GUL_MIKTARI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (YOLDAKİ GÜL MİKTARI)");
            });
    };
}

export function fabrikadakiGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/aractasima/findNullFabrikayaTeslimEtme/` + date)
            .then(result => result.data)
            .then(result => result.map(line => line.fabrikadakiAgirlik).reduce((prev, next) => prev + next, 0))
            .then(result =>
                dispatch({
                    type: YONETICI_FABRIKADAKI_GUL_MIKTARI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (FABRİKAYA ULAŞAN GÜL MİKTARI)");
            });
    };
}

export function kullanilanGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/findNotNullBitisZamani/` + date)
            .then(result => result.data)
            .then(result => result.map(line => line.kullanilanGulMiktar).reduce((prev, next) => prev + next, 0))
            .then(result =>
                dispatch({
                    type: YONETICI_KULLANILAN_GUL_MIKTARI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KULLANILAN GÜL MİKTARI)");
            });
    };
}

export function kantarToplananGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kantarGul/toplananGulKantarList/` + date)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: YONETICI_ANA_SAYFA_KANTAR_TOPLAM_GUL_MIKTARI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KANTARDA TOPLANAN GÜL MİKTARI)");
            });
    };
}

export function kazanKullanilanGulMiktari(date) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/distilasyonIslemler/findall/` + date)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: YONETICI_KAZAN_KULLANILAN_GUL_MIKTARI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KAZAN KULLANILAN GÜL MİKTARI)");
            });
    };
}
