import React from "react";
import "./AracYonetimSayfasi.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAracListesi, aracKaydet, deleteArac } from "./AracYonetimAction";
import { Icon, Table, Modal, Button, Input, Form, Pagination, Sticky, Header, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { Navbar } from "react-bootstrap";

class AracYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arac: {},
            aracPlaka: "",
            aracKapasitesi: "",
            show: false,
            open: false,
            filter: "",
            filtered: []
        };
    }

    componentWillMount() {
        this.setState({
            filtered: this.props.state.AracYonetimReducer.aracList
        });
    }

    componentDidMount() {
        this.props.getAracListesi();
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.AracYonetimReducer.aracList;
        updatedList = this.props.state.AracYonetimReducer.aracList.filter(function(item) {
            return item.aracPlaka.toLowerCase().includes(filter);
        });

        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    open = () => {
        this.setState({ open: true });
    };

    close = () => this.setState({ open: false, show: false });

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeInModel = post => {
        this.setState({
            open: true,

            id: post.id,
            aracPlaka: post.aracPlaka,
            aracKapasitesi: post.aracKapasitesi
        });
    };

    deleteAracModel = aracBilgileri => {
        this.setState({
            show: true,
            arac: aracBilgileri
        });
    };

    deleteArac = arac => {
        this.props.deleteArac(this.state.arac);

        if (this.props.state.AracYonetimReducer.silmeDurum == "SUCCESS") {
            this.setState({ show: false });
        } else if (this.props.state.AracYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    clearState = () => {
        this.setState({
            aracPlaka: "",
            aracKapasitesi: "",
            show: false,
            open: false
        });
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.AracYonetimReducer.aracList
        });

        if (next.state.AracYonetimReducer.kayitDurumu === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.AracYonetimReducer.kayitDurumu === "ERROR") {
            toast.error("HATA! Tekrar Eden Araç Plakası Girildi! Lütfen Kontrol Ediniz!");
        }
        if (next.state.AracYonetimReducer.silmeDurum === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.AracYonetimReducer.silmeDurum === "ERROR") {
            toast.error("HATA! Seçilen araç bilgileri silinemedi! İlgili araç diğer sayfalarda kullanılıyor olabilir !");
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.aracKaydet(this.state);
        if (this.props.state.AracYonetimReducer.kayitDurumu == "SUCCESS") {
            this.setState({
                open: false
            });
        } else if (this.props.state.AracYonetimReducer.kayitDurumu == "ERROR") {
            this.setState({
                open: true
            });
        }
    };

    render() {
        return (
            <div className="container">
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>ARAÇLAR</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="AracYonetim">
                    <Modal className="scrolling" size="tiny" style={{ height: 200, position: "initial" }} open={this.state.show} onClose={this.close}>
                        <Modal.Header>ARAÇ SİL</Modal.Header>
                        <Modal.Content>
                            <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.close}>
                                HAYIR
                            </Button>
                            <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteArac} />
                        </Modal.Actions>
                        <ToastContainer />
                    </Modal>
                    &nbsp;
                    <Button onClick={this.open} color="green">
                        ARAÇ EKLE
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />
                    <Modal open={this.state.open} size="tiny" style={{ height: 350, position: "initial" }} centered={false}>
                        <Modal.Header>YENİ ARAÇ EKLE</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Araç Plakası:</label>
                                        <input id="aracPlaka" name="aracPlaka" value={this.state.aracPlaka} onChange={this.handleChange} placeholder="Araç Plakası" required />
                                    </Form.Field>

                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Araç Kapasitesi (Kg):</label>
                                        <input
                                            id="aracKapasitesi"
                                            name="aracKapasitesi"
                                            value={this.state.aracKapasitesi}
                                            onChange={this.handleChange}
                                            placeholder="Araç Kapasitesi"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            required
                                        />
                                    </Form.Field>

                                    <Button type="submit" color="green">
                                        KAYDET
                                    </Button>
                                    <Button color="red" onClick={this.clearState}>
                                        İPTAL
                                    </Button>
                                </Form>
                                <ToastContainer />
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                    <Table className="table table-striped" Pagination>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>ARAÇ PLAKA</Table.HeaderCell>
                                <Table.HeaderCell>ARAÇ KAPASİTESİ (KG)</Table.HeaderCell>
                                <Table.HeaderCell>DÜZENLE</Table.HeaderCell>
                                <Table.HeaderCell>SİL</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.aracPlaka}</Table.Cell>
                                    <Table.Cell>{post.aracKapasitesi}</Table.Cell>
                                    <Table.Cell>
                                        <Button color="blue" onClick={() => this.changeInModel(post)}>
                                            DÜZENLE
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button color="red" onClick={() => this.deleteAracModel(post)}>
                                            SİL
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getAracListesi,
    aracKaydet,
    deleteArac
};
const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AracYonetimSayfasi);
