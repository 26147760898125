import React from "react";
import "./YoldakiAraclarYonetimSayfasi.css";
import { getYoldakiAracListesi } from "./YoldakiAraclarYonetimAction";
import { Icon, Table, Input, Menu } from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import "semantic-ui-calendar-react";
import "react-datetime/css/react-datetime.css";
import ChangeDate from "../changeDate/ChangeDate";

class YoldakiAraclarYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            filtered: [],
            fabrikayaGelisZamani: "",
            teslimAlan: { id: "" },
            teslimEden: { id: "" },
            aracTeslimAlmaZaman: new Date().toISOString(),
            kantardakiAgirlik: "",
            fabrikadakiAgirlik: "",
            bdate: "",
            open: false,
            showTeslimAlmaZaman: [],
        };
    }

    componentDidMount() {
        this.props.getYoldakiAracListesi(this.state.aracTeslimAlmaZaman);
    }

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.YoldakiAraclarYonetimReducer.yoldakiAracList
        });

        var arr;
        arr = [];
        let i;
        for(i = 0; i < next.state.YoldakiAraclarYonetimReducer.yoldakiAracList.length; i++) {
            if(next.state.YoldakiAraclarYonetimReducer.yoldakiAracList[i].aracTeslimAlmaZaman != null) {
                let today = next.state.YoldakiAraclarYonetimReducer.yoldakiAracList[i].aracTeslimAlmaZaman;
                let todayNew = ChangeDate.changeDate(today);
                const data = {
                    id: next.state.YoldakiAraclarYonetimReducer.yoldakiAracList[i].id,
                    date : todayNew
                }
                arr.push(data);
            }
        }

        this.setState({showTeslimAlmaZaman: arr});
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.YoldakiAraclarYonetimReducer.yoldakiAracList;
        updatedList = this.props.state.YoldakiAraclarYonetimReducer.yoldakiAracList.filter(function(item) {
            return item.teslimAlan.aracPlaka.toLowerCase().includes(filter) || item.teslimEden.kantarAd.toLowerCase().includes(filter) || item.aracTeslimAlmaZaman.toLowerCase().includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>YOLDAKİ ARAÇLAR</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="YoldakiAraclarYonetimSayfasi">
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>ARAÇ PLAKASI</Table.HeaderCell>
                                <Table.HeaderCell>GELDİĞİ KANTAR</Table.HeaderCell>
                                <Table.HeaderCell>YOLA ÇIKMA ZAMANI</Table.HeaderCell>
                                <Table.HeaderCell>GÜL MİKTARI (KG)</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.teslimAlan.aracPlaka}</Table.Cell>
                                    <Table.Cell>{post.teslimEden.kantarAd}</Table.Cell>
                                    <Table.Cell>
                                        {this.state.showTeslimAlmaZaman[this.state.filtered.indexOf(post)].date}
                                    </Table.Cell>
                                    <Table.Cell>{post.kantardakiAgirlik}</Table.Cell>
                                </Table.Row>
                            ))}
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell style={{ fontWeight: "bold" }}>TOPLAM GÜL MİKTARI (KG):</Table.Cell>
                                <Table.Cell style={{ fontWeight: "bold" }}>
                                    {this.props.state.YoldakiAraclarYonetimReducer.yoldakiAracList
                                        .map(line => line.kantardakiAgirlik)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getYoldakiAracListesi
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YoldakiAraclarYonetimSayfasi);
