import React from "react";
import "./YoneticiBitenIslemlerSayfasi.css";
import { getBitenIslemlerListesi, getIslemTurListesi } from "./YoneticiBitenIslemlerAction";
import { Icon, Table, Button, Input, Menu, Label, Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import ChangeDate from "../changeDate/ChangeDate";

class YoneticiBitenIslemlerSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            islemTur: { id: "", islemAd: "" },
            bitenIslemler: {},
            show: false,
            open: false,
            loading: false,
            filter: "",
            filtered: [],
            index: 0,
            bdate: new Date(),
            showBaslamaZamanEkstraksiyon: [{}],
            showBaslamaZamanRecine: [{}],
            showBaslamaZamanYagliSuDistilasyon: [{}],
            showBaslamaZamanCicekDistilasyon: [{}],
            showBaslamaZamanKohibasyon: [{}],
            showBaslamaZamanAbsolut: [{}],
        };
    }

    componentDidMount() {
        this.props.getBitenIslemlerListesi(this.state.bdate.toISOString());
        this.props.getIslemTurListesi();
    }

    componentWillMount() { }

    submit = () => {
        this.props.getBitenIslemlerListesi(this.state.bdate.toISOString());
        this.loading();
    };

    open = () => {
        this.setState({ open: true });
    };

    loading = () => {
        this.setState({ loading: true });
    };

    sayac = () => {
        this.setState({ index: this.index + 1 });
    };

    close = () => {
        this.setState({ open: false, show: false, loading: false });
    };

    handleIslemTur = (event, { islemId, islemAd }) => {
        this.setState({
            islemTur: { id: islemId, islemAd: islemAd }
        });
        this.filterList(undefined, islemId);
    };

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter, islemId = this.state.islemTur.id, bitenIslemlerListe = this.props.state.YoneticiBitenIslemlerReducer.bitenIslemlerList) => {
        var updatedList = bitenIslemlerListe.filter(item => item.kazan.kazanAdi.toLowerCase().includes(filter) && islemId === item.islemTur.id);
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleDate = date => {
        this.setState({ bdate: date });
    };

    changeInModel = post => {
        this.setState({
            open: true
        });
    };

    componentWillReceiveProps(next) {
        if (next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList !== this.props.state.YoneticiBitenIslemlerReducer.bitenIslemlerList) {
            this.filterList(undefined, undefined, next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList);
            this.close();

            var  arr1, arr2, arr3, arr4, arr5, arr6;
            arr1 = [];arr2 = [];arr3 = [];
            arr4 = [];arr5 = [];arr6 = [];

            let i;
            for(i = 0; i < next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList.length; i++) {
                if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].baslamaZaman != null) {
                    let today = next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].baslamaZaman;
                    let todayNew = ChangeDate.changeDate(today);

                    const data = {
                        id: next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].id,
                        date : todayNew
                    }
                    if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].islemTur.id == 1) {
                        arr1.push(data);
                    }
                    else if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].islemTur.id == 2) {
                        arr2.push(data);
                    }
                    else if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].islemTur.id == 3) {
                        arr3.push(data);
                    }
                    else if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].islemTur.id == 4) {
                        arr4.push(data);
                    }
                    else if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].islemTur.id == 5) {
                        arr5.push(data);
                    }
                    else if(next.state.YoneticiBitenIslemlerReducer.bitenIslemlerList[i].islemTur.id == 6) {
                        arr6.push(data);
                    }
                }
            }

            this.setState({
                showBaslamaZamanEkstraksiyon: arr5,
                showBaslamaZamanRecine: arr4,
                showBaslamaZamanYagliSuDistilasyon: arr2,
                showBaslamaZamanCicekDistilasyon: arr1,
                showBaslamaZamanKohibasyon: arr3,
                showBaslamaZamanAbsolut: arr6,
            });
        }
    }

    clearState = () => {
        this.setState({
            show: false,
            open: false
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            open: false
        });
    };

    render() {
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <Dimmer active={this.state.loading} page>
                    <Loader content="Yükleniyor." />
                </Dimmer>
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>BİTEN İŞLEMLER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="BitenIslemlerYonetim">
                    <Menu widths={8} pointing secondary color="blue">
                        {this.props.state.YoneticiBitenIslemlerReducer.islemList.map(post => (
                            <Menu.Item islemAd={post.value.islemAd} islemId={post.value.id} active={this.state.islemTur.id === post.value.id} onClick={this.handleIslemTur}>
                                {post.value.islemAd}
                            </Menu.Item>
                        ))}
                    </Menu>

                    {[1, 2, 3, 4, 5, 6].includes(this.state.islemTur.id) ? (
                        <div>
                            <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                                TARİH:
                            </Label>
                            <Input>
                                <Datetime timeFormat={false} value={this.state.bdate} defaultValue={new Date()} onChange={this.handleDate} dateFormat="DD/MM/YYYY" closeOnSelect required />
                            </Input>
                            <Button style={{ margin: 5 }} onClick={this.submit} color="blue">
                                BUL
                            </Button>
                            <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                            <Icon link name="times" onClick={this.clearFilter} />

                            <Table className="table table-striped">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                        <Table.HeaderCell>KAZAN ADI</Table.HeaderCell>
                                        <Table.HeaderCell>BAŞLAMA ZAMANI</Table.HeaderCell>
                                        {[1,5].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN GÜL MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[2,3].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN YAĞLI SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[4].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN KOKULU SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[1].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[5].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN HEKZAN MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[6].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN ALKOL MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[6].includes(this.state.islemTur.id) ? <Table.HeaderCell>KULLANILAN KATI YAĞ MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[1,2,3].includes(this.state.islemTur.id) ? (<Table.HeaderCell>ÜRETİLEN GÜL YAĞI MİKTARI (KG)</Table.HeaderCell>) : ""}
                                        {[2].includes(this.state.islemTur.id) ? (<Table.HeaderCell>ÜRETİLEN GÜL SUYU MİKTARI (KG)</Table.HeaderCell>) : ""}
                                        {[5].includes(this.state.islemTur.id) ? <Table.HeaderCell>ÜRETİLEN KATI YAĞ MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[1].includes(this.state.islemTur.id) ? <Table.HeaderCell>ÜRETİLEN YAĞLI SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[3].includes(this.state.islemTur.id) ? <Table.HeaderCell>ÜRETİLEN KOKULU SU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[4].includes(this.state.islemTur.id) ? <Table.HeaderCell>ÜRETİLEN KOKU MİKTARI (KG)</Table.HeaderCell> : ""}
                                        {[6].includes(this.state.islemTur.id) ? (<Table.HeaderCell>ÜRETİLEN ABSOLÜT MİKTARI (KG)</Table.HeaderCell>) : ""}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.state.filtered.slice().reverse().map(post => (
                                        <Table.Row key={post.id}>
                                            <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                            <Table.Cell>{post.kazan.kazanAdi} </Table.Cell>
                                            {[1].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanCicekDistilasyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[2].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanYagliSuDistilasyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[3].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanKohibasyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[4].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanRecine[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[5].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanEkstraksiyon[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[6].includes(this.state.islemTur.id) ?
                                                <Table.Cell>{this.state.showBaslamaZamanAbsolut[this.state.filtered.indexOf(post)].date}</Table.Cell> : ""}
                                            {[1,5].includes(this.state.islemTur.id) ? <Table.Cell>{post.kullanilanGulMiktar}</Table.Cell> : ""}
                                            {[2,3].includes(this.state.islemTur.id) ? <Table.Cell>{post.kullanilanYagliSuMiktar}</Table.Cell> : ""}
                                            {[4].includes(this.state.islemTur.id) ? <Table.Cell>{post.kullanilanKokuluSuMiktar}</Table.Cell> : ""}
                                            {[1].includes(this.state.islemTur.id) ? <Table.Cell>{post.kullanilanSuMiktar}</Table.Cell> : ""}
                                            {[5,6].includes(this.state.islemTur.id) ? <Table.Cell>{post.kullanilanAlkolMiktar}</Table.Cell> : ""}
                                            {[6].includes(this.state.islemTur.id) ? <Table.Cell>{post.kullanilanKatiYagMiktar}</Table.Cell> : ""}
                                            {[1,2,3].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenYag}</Table.Cell> : ""}
                                            {[2].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenKokuMiktar}</Table.Cell> : ""}
                                            {[5].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenKatiYagMiktar}</Table.Cell> : ""}
                                            {[1].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenYagliSuMiktar}</Table.Cell> : ""}
                                            {[3].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenKokuluSuMiktar}</Table.Cell> : ""}
                                            {[4].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenKokuMiktar}</Table.Cell> : ""}
                                            {[6].includes(this.state.islemTur.id) ? <Table.Cell>{post.uretilenAbsolutMiktar}</Table.Cell> : ""}
                                        </Table.Row>
                                    ))}
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell>
                                            <h4>TOPLAM (KG):</h4>
                                        </Table.Cell>
                                        {[1,5].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{ fontWeight: "bold" }}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanGulMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[2,3].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{ fontWeight: "bold" }}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanYagliSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[4].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{ fontWeight: "bold" }}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanKokuluSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[1].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{ fontWeight: "bold" }}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[5,6].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{ fontWeight: "bold" }}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanAlkolMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[6].includes(this.state.islemTur.id) ? (
                                            <Table.Cell style={{ fontWeight: "bold" }}>
                                                {this.state.filtered
                                                    .map(line => line.kullanilanKatiYagMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[1,2,3].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenYag)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[2].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenKokuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                            ""
                                        )}
                                        {[5].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenKatiYagMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[1].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenYagliSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[3].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenKokuluSuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[4].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenKokuMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                        {[6].includes(this.state.islemTur.id) ? (
                                            <Table.Cell>
                                                {this.state.filtered
                                                    .map(line => line.uretilenAbsolutMiktar)
                                                    .reduce((prev, next) => prev + next, 0)
                                                    .toFixed(3)}
                                            </Table.Cell>
                                        ) : (
                                                ""
                                            )}
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </div>
                    ) : (
                            <h3 style={{ color: "red", textAlign: "center" }}>LÜTFEN İŞLEM TÜRÜNÜ SEÇİN</h3>
                        )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getBitenIslemlerListesi,
    getIslemTurListesi
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YoneticiBitenIslemlerSayfasi);
