import axios from "axios";

import { API_PATH } from "../config/Config";

export const LIST_GETIR_KAZAN = "LIST_GETIR_KAZAN";
export const CREATE_SUCCESS_KAZAN = "CREATE_SUCCESS_KAZAN";
export const CREATE_FAIL_KAZAN = "CREATE_FAIL_KAZAN";
export const DELETE_SUCCESS_KAZAN = "DELETE_SUCCESS_KAZAN";
export const DELETE_FAIL_KAZAN = "DELETE_FAIL_KAZAN";
export const DELETE = "DELETE";

export function getKazanListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/kazan/findall`)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: LIST_GETIR_KAZAN,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KAZAN LİSTESİ)");
            });
    };
}

function kazanKaydetmeKontrolu(sonuc, kazanBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS_KAZAN,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL_KAZAN,
            payload: "ERROR"
        };
    }
}

export function kazanKaydet(kazanBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/kazan/create`, {
            method: "POST",
            data: kazanBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(kazanKaydetmeKontrolu(result, kazanBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (KAZAN KAYDETME)");
            });
    };
}

function silmeKontrolu(sonuc, kazanBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: DELETE_SUCCESS_KAZAN,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: DELETE_FAIL_KAZAN,
            payload: "ERROR"
        };
    }
}

export function deleteKazan(kazanBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/kazan/delete`, {
            method: "DELETE",
            data: kazanBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => dispatch(silmeKontrolu(result, kazanBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (KAZAN LİSTESİ)");
            });
    };
}
