import { ALACAK_VERECEK_LIST_GETIR, KOY_LIST_GETIR } from "./AlacakVerecekDurumAction";

export default function AlacakVerecekDurumReducer(
    state = {
        alacakVerecekgelenList: [],
        koyList: [],
        alacak_verecek: {},
        fetching: true,
        error: ""
    },
    action
) {
    switch (action.type) {
        case ALACAK_VERECEK_LIST_GETIR:
            return {
                ...state,
                alacakVerecekgelenList: action.payload
            };
        case KOY_LIST_GETIR:
            return {
                ...state,
                koyList: action.payload
            };

        default:
            return state;
    }
}
