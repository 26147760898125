import React from "react";
import "./KoyYonetimSayfasi.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getKoyListesi, koyKaydet, deleteKoy } from "./KoyYonetimAction";
import { Icon, Table, Modal, Button, Input, Form, Menu } from "semantic-ui-react";
import { connect } from "react-redux";

class KoyYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            koy: {},
            id: "",
            koyAd: "",
            show: false,
            open: false,
            filter: "",
            filtered: []
        };
    }

    componentDidMount() {
        this.props.getKoyListesi();
    }

    componentWillMount() {
        this.setState({
            filtered: this.props.state.KoyYonetimReducer.koygelenList
        });
    }

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.KoyYonetimReducer.koygelenList
        });

        if (next.state.KoyYonetimReducer.kayitDurumu === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.KoyYonetimReducer.kayitDurumu === "ERROR") {
            toast.error("HATA!  Lütfen Kontrol Ediniz!");
        }

        if (next.state.KoyYonetimReducer.silmeDurum === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.KoyYonetimReducer.silmeDurum === "ERROR") {
            toast.error("HATA! Seçilen köy bilgileri silinemedi! İlgili köy diğer sayfalarda kullanılıyor olabilir !");
        }
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.KoyYonetimReducer.koygelenList;
        updatedList = this.props.state.KoyYonetimReducer.koygelenList.filter(function(item) {
            return item.koyAd.toLowerCase().includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeInModel = post => {
        this.setState({
            open: true,
            id: post.id,
            koyAd: post.koyAd
        });
    };

    deleteKoyModel = koyBilgileri => {
        this.setState({
            show: true,
            koy: koyBilgileri
        });
    };

    deleteKoy = koy => {
        this.props.deleteKoy(this.state.koy);

        if (this.props.state.KoyYonetimReducer.silmeDurum == "SUCCESS") {
            this.setState({ show: false });
        } else if (this.props.state.KoyYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    clearState = () => {
        this.setState({
            id: "",
            koyAd: "",
            show: false,
            open: false
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.koyKaydet(this.state);

        if (this.props.state.KoyYonetimReducer.kayitDurumu == "SUCCESS") {
            this.setState({
                open: false
            });
        } else if (this.props.state.KoyYonetimReducer.kayitDurumu == "ERROR") {
            this.setState({
                open: true
            });
        }
    };

    render() {
        return (
            <div className="container">
                {" "}
                &nbsp;
                <Modal className="scrolling" size="tiny" style={{ height: 200, position: "initial" }} open={this.state.show} onClose={this.close}>
                    <Modal.Header>KÖY SİL</Modal.Header>
                    <Modal.Content>
                        <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            HAYIR
                        </Button>
                        <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteKoy} />
                    </Modal.Actions>

                    <ToastContainer />
                </Modal>{" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>KÖYLER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="KoyYonetim">
                    <Button onClick={this.open} color="green">
                        KÖY EKLE
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Modal open={this.state.open} size="tiny" style={{ height: 250, position: "initial" }} centered={false}>
                        <Modal.Header>YENİ KÖY EKLE</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <label style={{ fontSize: 18 }}>Köy Adı:</label>
                                        <input id="koyAd" name="koyAd" value={this.state.koyAd} onChange={this.handleChange} placeholder="Köy Adı" required />
                                    </Form.Field>

                                    <Button type="submit" color="green">
                                        KAYDET
                                    </Button>
                                    <Button color="red" onClick={this.clearState}>
                                        İPTAL
                                    </Button>

                                    <ToastContainer />
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>KÖY ADI</Table.HeaderCell>
                                <Table.HeaderCell>DÜZENLE</Table.HeaderCell>
                                <Table.HeaderCell>SİL</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.koyAd} </Table.Cell>
                                    <Table.Cell>
                                        <Button color="blue" onClick={() => this.changeInModel(post)}>
                                            DÜZENLE
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button color="red" onClick={() => this.deleteKoyModel(post)}>
                                            SİL
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getKoyListesi,
    koyKaydet,
    deleteKoy
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KoyYonetimSayfasi);
