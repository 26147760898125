import { combineReducers } from 'redux';
import UreticiYonetimReducer from '../components/ureticiYonetimSayfasi/UreticiYonetimReducer'
import KoyYonetimReducer from '../components/koyYonetimSayfasi/KoyYonetimReducer';
import KantarYonetimReducer from '../components/kantarYonetimSayfasi/KantarYonetimReducer';
import YoneticiBitenIslemlerReducer from '../components/yoneticiBitenIslemlerSayfasi/YoneticiBitenIslemlerReducer';
import UretilenUrunlerReducer from '../components/uretilenUrunlerSayfasi/UretilenUrunlerReducer';
import KazanYonetimReducer from '../components/kazanYonetimSayfasi/KazanYonetimReducer';
import AracYonetimReducer from '../components/aracYonetimSayfasi/AracYonetimReducer';
import KantardaToplananGulReducer from '../components/kantardaToplananGulYonetimSayfasi/KantardaToplananGulReducer';
import TeslimAlinanGulMiktariReducer from '../components/teslimAlinanGulMiktariSayfasi/TeslimAlinanGulMiktariReducer';
import AlacakVerecekDurumReducer from '../components/alacakVerecekDurumSayfasi/AlacakVerecekDurumReducer';
import SozlesmeYonetimReducer from '../components/sozlesmeYonetimSayfasi/SozlesmeYonetimReducer';
import YoldakiAraclarYonetimReducer from '../components/yoldakiAraclarYonetimSayfasi/YoldakiAraclarYonetimReducer';
import FabrikayaUlasanAraclarReducer from '../components/fabrikayaUlasanAraclarYonetimSayfasi/FabrikayaUlasanAraclarReducer';
import DevamEdenIslemlerReducer from '../components/devamEdenIslemlerYonetimSayfasi/DevamEdenIslemlerReducer';
import UyariMesajlariYonetimReducer from '../components/uyariMesajlariYonetimSayfasi/UyariMesajlariYonetimReducer';
import SifreDegisimReducer from '../components/sifreDegisimEkrani/SifreDegisimReducer';
import UserReducer from '../components/login/UserReducer';
import YoneticiAnaSayfaReducer from '../components/yoneticiAnaSayfa/YoneticiAnaSayfaReducer';



export default combineReducers({
	UserReducer,
	UreticiYonetimReducer,
	KoyYonetimReducer,
	KantarYonetimReducer,
	KazanYonetimReducer,
	YoneticiBitenIslemlerReducer,
	UretilenUrunlerReducer,
	SozlesmeYonetimReducer,
	AracYonetimReducer,
	TeslimAlinanGulMiktariReducer,
	AlacakVerecekDurumReducer,
	FabrikayaUlasanAraclarReducer,
	KantardaToplananGulReducer,
	YoldakiAraclarYonetimReducer,
	DevamEdenIslemlerReducer,
	UyariMesajlariYonetimReducer,
	SifreDegisimReducer,
	YoneticiAnaSayfaReducer
})