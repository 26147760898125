import React from "react";
import "./YoneticiAnaSayfa.css";
import {
    teslimAlinanGulMiktari,
    uretilenYagMiktari,
    tasinanVeBekleyenGulMiktari,
    yoldakiGulMiktari,
    fabrikadakiGulMiktari,
    kullanilanGulMiktari,
    kantarToplananGulMiktari,
    kazanKullanilanGulMiktari
} from "./YoneticiAnaSayfaAction";
import { Button, Grid, Segment, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactSwipe from "react-swipe";
import TimerMachine from "react-timer-machine";
import Timer from "react-compound-timer";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

class YoneticiAnaSayfa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tarih: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
            bekleyenGulMiktari: "",
            toplamGulMiktari: ""
        };
    }

    componentDidMount() {
        this.props.teslimAlinanGulMiktari(this.state.tarih);
        this.props.uretilenYagMiktari(this.state.tarih);
        this.props.tasinanVeBekleyenGulMiktari(this.state.tarih);
        this.props.yoldakiGulMiktari(this.state.tarih);
        this.props.fabrikadakiGulMiktari(this.state.tarih);
        this.props.kullanilanGulMiktari(this.state.tarih);
        this.props.kantarToplananGulMiktari(this.state.tarih);
        this.props.kazanKullanilanGulMiktari(this.state.tarih);
    }

    componentWillReceiveProps(next) {
        this.setState({
            bekleyenGulMiktari: next.state.YoneticiAnaSayfaReducer.tasinanVeBekleyenGulMiktari.map(line => line.bekleyenGulMiktari).reduce((prev, next) => prev + next, 0),
            toplamGulMiktari: next.state.YoneticiAnaSayfaReducer.tasinanVeBekleyenGulMiktari.map(line => line.toplamGulMiktari).reduce((prev, next) => prev + next, 0)
        });
    }

    mergeData = [];
    mergeValue = [];

    addDatas = (data, value) => {
        this.mergeData.push(data);
        this.mergeValue.push(value);
    };

    sumValue = (index, value) => {
        this.mergeValue[index] = this.mergeValue[index] + value;
    };

    render() {
        let reactSwipeEl;
        return (
            <div className="container">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>ANASAYFA</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="YoneticiAnaSayfa">
                    {" "}
                    &nbsp;
                    <div>
                        <text style={{ fontSize: 20, fontWeight: "bolder" }}>GÜL VE YAĞ MİKTARLARI</text>
                        <br />
                        <br />
                        <Grid columns="equal">
                            <Grid.Row stretched>
                                <Grid.Column>
                                    <Segment style={{ backgroundColor: "aliceblue" }}>
                                        <h3>
                                            TESLİM ALINAN GÜL MİKTARI (KG):
                                            <br /> {parseFloat(this.props.state.YoneticiAnaSayfaReducer.teslimAlinanGulMiktar).toFixed(3)}
                                        </h3>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment style={{ backgroundColor: "aliceblue" }}>
                                        <h3>
                                            BEKLEYEN GÜL MİKTARI (KG):
                                            <br /> {(this.props.state.YoneticiAnaSayfaReducer.teslimAlinanGulMiktar - this.props.state.YoneticiAnaSayfaReducer.yoldakiGulMiktari).toFixed(3)}{" "}
                                        </h3>{" "}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment style={{ backgroundColor: "aliceblue" }}>
                                        <h3>
                                            ÜRETİLEN YAĞ MİKTARI (KG):
                                            <br /> {parseFloat(this.props.state.YoneticiAnaSayfaReducer.uretilenYagMiktar).toFixed(3)}{" "}
                                        </h3>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row stretched>
                                <Grid.Column>
                                    <Segment style={{ backgroundColor: "aliceblue" }}>
                                        <h3>
                                            YOLDAKİ GÜL MİKTARI (KG):
                                            <br /> {parseFloat(this.props.state.YoneticiAnaSayfaReducer.yoldakiGulMiktari).toFixed(3)}{" "}
                                        </h3>{" "}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment style={{ backgroundColor: "aliceblue" }}>
                                        <h3>
                                            FABRİKAYA ULAŞAN GÜL MİKTARI (KG):
                                            <br /> {parseFloat(this.props.state.YoneticiAnaSayfaReducer.fabrikadakiGulMiktari).toFixed(3)}{" "}
                                        </h3>{" "}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment style={{ backgroundColor: "aliceblue" }}>
                                        <h3>
                                            FABRİKADA İŞLENEN GÜL MİKTARI (KG):
                                            <br /> {parseFloat(this.props.state.YoneticiAnaSayfaReducer.kullanilanGulMiktari).toFixed(3)}{" "}
                                        </h3>{" "}
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>{" "}
                    &nbsp;
                    <div>
                        <text style={{ fontSize: 20, fontWeight: "bolder" }}>KANTARLAR</text>
                        <ReactSwipe
                            autoPlay
                            className="carousel"
                            key={this.props.state.YoneticiAnaSayfaReducer.gunlukKantarGulList.length}
                            swipeOptions={{ auto: 4000 }}
                            ref={el => (reactSwipeEl = el)}
                        >
                            {this.props.state.YoneticiAnaSayfaReducer.gunlukKantarGulList.map(post => (
                                <div>
                                    <br />
                                    <Grid columns="equal" divided>
                                        <Grid.Column>
                                            <Segment>
                                                <h3>KANTAR ADI: </h3>
                                                <p style={{ fontSize: 18, fontWeight: "bold" }}>{post.kantar.kantarAd}</p>
                                                <h3> BUGÜN TOPLANAN GÜL MİKTARI (KG):</h3>
                                                <p style={{ fontSize: 18, fontWeight: "bold" }}>{post.toplamGulMiktari.toFixed(3)}</p>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            ))}
                        </ReactSwipe>
                        <br />
                        <Button style={{ fontSize: 15, color: "white", backgroundColor: "#be2828" }} onClick={() => reactSwipeEl.prev()}>
                            ÖNCEKİ
                        </Button>
                        <Button style={{ fontSize: 15, color: "white", backgroundColor: "#be2828" }} onClick={() => reactSwipeEl.next()}>
                            SONRAKİ
                        </Button>
                    </div>
                    <br />
                    <text style={{ fontSize: 20, fontWeight: "bolder" }}>ÇALIŞAN KAZANLAR</text>
                    <div>
                        <br />
                        {this.props.state.YoneticiAnaSayfaReducer.kazanKullanilanGulMiktari.length != 0 ? (
                            <Grid columns="6" style={{ justifyContent: "center" }}>
                                <Grid.Row stretched>
                                    {this.props.state.YoneticiAnaSayfaReducer.kazanKullanilanGulMiktari.map(item =>
                                        this.mergeData.includes(item.kazan.kazanAdi) === false
                                            ? this.addDatas(item.kazan.kazanAdi, item.kullanilanGulMiktar)
                                            : this.sumValue(this.mergeData.indexOf(item.kazan.kazanAdi), item.kullanilanGulMiktar)
                                    )}
                                    {this.mergeData.map((post, index) => (
                                        <Grid.Column key={post}>
                                            <Segment style={{ backgroundColor: "seashell" }}>
                                                <h3>
                                                    KAZAN ADI: <br />
                                                    {post} <br />
                                                    <br />
                                                    GÜL MİKTARI: <br /> {this.mergeValue[index]}
                                                    <br />
                                                    <br />
                                                    İŞLEM:{" "}
                                                </h3>
                                                <h4>
                                                    {this.props.state.YoneticiAnaSayfaReducer.kazanKullanilanGulMiktari.map(birim =>
                                                        post == birim.kazan.kazanAdi ? (
                                                            birim.bitisZaman === null ? (
                                                                <div>
                                                                    {birim.islemTur.islemAd}
                                                                    <br />
                                                                    <Timer
                                                                        initialTime={
                                                                            birim.bitisZaman === null
                                                                                ? new Date().getTime() - new Date(birim.baslamaZaman).getTime()
                                                                                : new Date(birim.bitisZaman).getTime() - new Date(birim.baslamaZaman).getTime()
                                                                        }
                                                                        lastUnit="h"
                                                                        formatValue={value => `${value < 10 ? `0${value}` : value}`}
                                                                        startImmediately={birim.bitisZaman === null}
                                                                    >
                                                                        <Timer.Hours />:
                                                                        <Timer.Minutes />:
                                                                        <Timer.Seconds />
                                                                    </Timer>
                                                                    {/* <TimerMachine
                                                                        timeStart={
                                                                            birim.bitisZaman === null
                                                                                ? new Date().getTime() - new Date(birim.baslamaZaman).getTime()
                                                                                : new Date(birim.bitisZaman).getTime() - new Date(birim.baslamaZaman).getTime()
                                                                        }
                                                                        started={birim.bitisZaman === null}
                                                                        formatTimer={(time, ms) => moment.duration(ms, "milliseconds").format("hh:mm:ss", { trim: false })}
                                                                    /> */}
                                                                    <br />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {birim.islemTur.islemAd}
                                                                    <br />({new Date(birim.baslamaZaman).toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit" })}-
                                                                    {new Date(birim.bitisZaman).toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit" })})<br />
                                                                    <Timer
                                                                        initialTime={
                                                                            birim.bitisZaman === null
                                                                                ? new Date().getTime() - new Date(birim.baslamaZaman).getTime()
                                                                                : new Date(birim.bitisZaman).getTime() - new Date(birim.baslamaZaman).getTime()
                                                                        }
                                                                        lastUnit="h"
                                                                        formatValue={value => `${value < 10 ? `0${value}` : value}`}
                                                                        startImmediately={birim.bitisZaman === null}
                                                                    >
                                                                        <Timer.Hours />:
                                                                        <Timer.Minutes />
                                                                    </Timer>                                                                    
                                                                    {/* <TimerMachine
                                                                        timeStart={
                                                                            birim.bitisZaman === null
                                                                                ? new Date().getTime() - new Date(birim.baslamaZaman).getTime()
                                                                                : new Date(birim.bitisZaman).getTime() - new Date(birim.baslamaZaman).getTime()
                                                                        }
                                                                        started={birim.bitisZaman === null}
                                                                        formatTimer={(time, ms) => moment.duration(ms, "milliseconds").format("hh:mm", { trim: false })}
                                                                    /> */}
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            )
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                                </h4>
                                            </Segment>
                                        </Grid.Column>
                                    ))}
                                </Grid.Row>
                            </Grid>
                        ) : (
                            <Grid columns="1" diveded style={{ justifyContent: "center" }}>
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <Segment style={{ backgroundColor: "seashell", margin: 10 }}>
                                            <h3 style={{ fontFamily: "Roboto", fontSize: 20 }}>Çalışan Kazan Bulunmamaktadır!</h3>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    </div>
                    <br />
                    <br />
                </div>
            </div >
        );
    }
}

// {this.props.state.YoneticiAnaSayfaReducer.kazanKullanilanGulMiktari.map((startTime) => 
//     (startTime.baslamaZaman ? <Countdown date={new Date(startTime.baslamaZaman)} /> : " ")
// )}

const mapDispatchToProps = {
    teslimAlinanGulMiktari,
    uretilenYagMiktari,
    tasinanVeBekleyenGulMiktari,
    yoldakiGulMiktari,
    fabrikadakiGulMiktari,
    kullanilanGulMiktari,
    kantarToplananGulMiktari,
    kazanKullanilanGulMiktari
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YoneticiAnaSayfa);
