import {
    TESLIM_ALINAN_GUL_MIKTAR,
    URETILEN_YAG_MIKTAR,
    TASINAN_VE_BEKLEYEN_GUL_MIKTAR,
    YONETICI_ANA_SAYFA_KANTAR_TOPLAM_GUL_MIKTARI,
    YONETICI_YOLDAKI_GUL_MIKTARI,
    YONETICI_FABRIKADAKI_GUL_MIKTARI,
    YONETICI_KULLANILAN_GUL_MIKTARI,
    YONETICI_KAZAN_KULLANILAN_GUL_MIKTARI
} from "./YoneticiAnaSayfaAction";

export default function yoneticiAnaSayfaReducer(
    state = {
        teslimAlinanGulMiktar: "",
        uretilenYagMiktar: "",
        gunlukKantarGulList: [],
        tasinanVeBekleyenGulMiktari: [],
        yoldakiGulMiktari: [],
        fabrikadakiGulMiktari: [],
        kullanilanGulMiktari: [],
        kazanKullanilanGulMiktari: []
    },
    action
) {
    switch (action.type) {
        case TESLIM_ALINAN_GUL_MIKTAR:
            return {
                ...state,
                teslimAlinanGulMiktar: action.payload
            };
        case URETILEN_YAG_MIKTAR:
            return {
                ...state,
                uretilenYagMiktar: action.payload
            };
        case TASINAN_VE_BEKLEYEN_GUL_MIKTAR:
            return {
                ...state,
                tasinanVeBekleyenGulMiktari: action.payload
            };
        case YONETICI_YOLDAKI_GUL_MIKTARI:
            return {
                ...state,
                yoldakiGulMiktari: action.payload
            };
        case YONETICI_FABRIKADAKI_GUL_MIKTARI:
            return {
                ...state,
                fabrikadakiGulMiktari: action.payload
            };
        case YONETICI_KULLANILAN_GUL_MIKTARI:
            return {
                ...state,
                kullanilanGulMiktari: action.payload
            };
        case YONETICI_ANA_SAYFA_KANTAR_TOPLAM_GUL_MIKTARI:
            return {
                ...state,
                gunlukKantarGulList: action.payload
            };
        case YONETICI_KAZAN_KULLANILAN_GUL_MIKTARI:
            return {
                ...state,
                kazanKullanilanGulMiktari: action.payload
            };
        default:
            return state;
    }
}
