export default class ChangeDate {
   static changeDate(date) {
       if(date == null)
           return "";
       else {
           const tarih = date.substring(0, 10);
           const saat = date.substring(11, 13);
           const dakika = date.substring(14, 16);
           const yil = tarih.substring(0, 4);
           var gun = parseInt(tarih.substring(8, 10));
           var ay = parseInt(tarih.substring(5, 7));

           var finalStr = "";
           var saatNew = parseInt(saat);
           if (saatNew < 20 && saatNew != 0) {
               const saatFinal = saatNew + 3 + "";
               finalStr = tarih + " " + saatFinal + ":" + dakika;
           } else if (saatNew == 21) {
               const saatFinal = "00";
               if (ay == 4 || ay == 6 || ay == 9 || ay == 11) {
                   if (gun == 30) {
                       gun = 1;
                       ay = ay + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   } else {
                       gun = gun + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   }
               } else if (ay == 1 || ay == 3 || ay == 5 || ay == 7 || ay == 8 || ay == 10) {
                   if (gun == 31) {
                       gun = 1;
                       ay = ay + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   } else {
                       gun = gun + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   }
               }
               finalStr = yil + "-" + ay + "-" + gun + " " + saatFinal + ":" + dakika;
           } else if (saatNew == 22) {
               const saatFinal = "01";
               if (ay == 4 || ay == 6 || ay == 9 || ay == 11) {
                   if (gun == 30) {
                       gun = 1;
                       ay = ay + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   } else {
                       gun = gun + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   }
               } else if (ay == 1 || ay == 3 || ay == 5 || ay == 7 || ay == 8 || ay == 10) {
                   if (gun == 31) {
                       gun = 1;
                       ay = ay + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   } else {
                       gun = gun + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   }
               }
               finalStr = yil + "-" + ay + "-" + gun + " " + saatFinal + ":" + dakika;
           } else if (saatNew == 23) {
               const saatFinal = "02";
               if (ay == 4 || ay == 6 || ay == 9 || ay == 11) {
                   if (gun == 30) {
                       gun = 1;
                       ay = ay + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   } else {
                       gun = gun + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   }
               } else if (ay == 1 || ay == 3 || ay == 5 || ay == 7 || ay == 8 || ay == 10) {
                   if (gun == 31) {
                       gun = 1;
                       ay = ay + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   } else {
                       gun = gun + 1;
                       if (ay < 10)
                           ay = "0" + ay;
                       if (gun < 10)
                           gun = "0" + gun;
                   }
               }
               finalStr = yil + "-" + ay + "-" + gun + " " + saatFinal + ":" + dakika;
           } else if (saat == "00") {
               const saatFinal = "03";
               finalStr = tarih + " " + saatFinal + ":" + dakika;
           }
           return finalStr;
       }
    }
}
