import React from "react";
import "./UretilenUrunlerSayfasi.css";
import { getBitenIslemlerListesi, getIslemTurListesi, getKazanListesi } from "./UretilenUrunlerAction";
import { Icon, Table, Button, Input, Menu, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import Datetime from "react-datetime";

class UretilenUrunlerSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            filtered: [],
            bdate: new Date()
        };
        this.props.getBitenIslemlerListesi(this.state.bdate.toISOString());
        this.props.getIslemTurListesi();
        this.props.getKazanListesi();
    }

    componentDidMount() {
        this.props.getBitenIslemlerListesi(this.state.bdate.toISOString());
        this.props.getIslemTurListesi();
        this.props.getKazanListesi();
    }

    submit = () => {
        this.props.getBitenIslemlerListesi(this.state.bdate.toISOString());
    };

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value);
    };

    filterList = (filter = this.state.filter, urunlerListe = this.props.state.UretilenUrunlerReducer.bitenIslemlerList) => {
        var updatedList = urunlerListe.filter(item => item.kazan.kazanAdi.toLowerCase().includes(filter));
        this.setState({
            filtered: this.reduceList(updatedList)
        });
    };

    reduceList = liste => {
        return this.props.state.UretilenUrunlerReducer.kazanList
            .map(kazan =>
                liste
                    .filter(islem => {
                        return islem.kazan.kazanAdi === kazan.kazanAdi;
                    })
                    .reduce(
                        (acc, cur) => ({
                            kazanAdi: kazan.kazanAdi,
                            siviYag: acc.siviYag + cur.uretilenSiviYagMiktar,
                            katiYag: acc.katiYag + cur.uretilenKatiYagMiktar,
                            yagliSu: acc.yagliSu + cur.uretilenYagliSuMiktar,
                            kokuluSu: acc.kokuluSu + cur.uretilenKokuluSuMiktar,
                            koku: acc.koku + cur.uretilenKokuMiktar
                        }),
                        { kazanAdi: kazan.kazanAdi, siviYag: 0, katiYag: 0, yagliSu: 0, kokuluSu: 0, koku: 0 }
                    )
            )
            .filter(item => {
                return item.siviYag !== 0 || item.katiYag !== 0 || item.yagliSu !== 0 || item.kokuluSu !== 0 || item.koku !== 0;
            });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("");
    };

    handleDate = date => {
        this.setState({ bdate: date });
    };

    componentWillReceiveProps(next) {
        if (
            next.state.UretilenUrunlerReducer.bitenIslemlerList !== this.props.state.UretilenUrunlerReducer.bitenIslemlerList ||
            next.state.UretilenUrunlerReducer.kazanList !== this.props.state.UretilenUrunlerReducer.kazanList
        ) {
            this.filterList(undefined, next.state.UretilenUrunlerReducer.bitenIslemlerList);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>ÜRETİLEN ÜRÜNLER</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="UretilenUrunler">
                    <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                        TARİH:
                    </Label>
                    <Input>
                        <Datetime timeFormat={false} value={this.state.bdate} defaultValue={new Date()} onChange={this.handleDate} dateFormat="DD/MM/YYYY" closeOnSelect required />
                    </Input>
                    <Button style={{ margin: 5 }} onClick={this.submit} color="blue">
                        BUL
                    </Button>
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />
                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>KAZAN ADI</Table.HeaderCell>
                                <Table.HeaderCell>SIVI GÜL YAĞI (KG)</Table.HeaderCell>
                                <Table.HeaderCell>KATI GÜL YAĞI (KG)</Table.HeaderCell>
                                <Table.HeaderCell>YAĞLI SU (KG)</Table.HeaderCell>
                                <Table.HeaderCell>KOKULU SU (KG)</Table.HeaderCell>
                                <Table.HeaderCell>KOKU (KG)</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.filtered.map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.indexOf(post) + 1}</Table.Cell>
                                    <Table.Cell>{post.kazanAdi} </Table.Cell>
                                    <Table.Cell>{post.siviYag === 0 ? "-" : post.siviYag} </Table.Cell>
                                    <Table.Cell>{post.katiYag === 0 ? "-" : post.katiYag} </Table.Cell>
                                    <Table.Cell>{post.yagliSu === 0 ? "-" : post.yagliSu} </Table.Cell>
                                    <Table.Cell>{post.kokuluSu === 0 ? "-" : post.kokuluSu} </Table.Cell>
                                    <Table.Cell>{post.koku === 0 ? "-" : post.koku} </Table.Cell>
                                </Table.Row>
                            ))}

                            <tr>
                                <th></th>
                                <th>TOPLAM (KG):</th>
                                <th>
                                    {this.state.filtered
                                        .map(line => line.siviYag)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                                <th>
                                    {this.state.filtered
                                        .map(line => line.katiYag)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                                <th>
                                    {this.state.filtered
                                        .map(line => line.yagliSu)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                                <th>
                                    {this.state.filtered
                                        .map(line => line.kokuluSu)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                                <th>
                                    {this.state.filtered
                                        .map(line => line.koku)
                                        .reduce((prev, next) => prev + next, 0)
                                        .toFixed(3)}
                                </th>
                            </tr>
                        </Table.Body>
                    </Table>
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getBitenIslemlerListesi,
    getIslemTurListesi,
    getKazanListesi
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UretilenUrunlerSayfasi);
