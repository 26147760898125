import { SUCCESS_SIFRE_DEGISIMI, FAIL_SIFRE_DEGISIMI, FAIL_SIFRE_UYUMU, SUCCESS_SIFRE_UYUMU } from "./SifreDegisimAction";

export default function SifreDegisimReducer(
    state = {
        sifreDegisimiOnayDurumu: " ",
        sifreEslesmeDurumu: "",
        error: ""
    },
    action
) {
    switch (action.type) {
        case SUCCESS_SIFRE_DEGISIMI:
            return {
                ...state,
                sifreDegisimiOnayDurumu: action.payload
            };

        case FAIL_SIFRE_DEGISIMI:
            return {
                ...state,
                sifreDegisimiOnayDurumu: action.payload
            };

        case SUCCESS_SIFRE_UYUMU:
            return {
                ...state,
                sifreEslesmeDurumu: action.payload
            };
        case FAIL_SIFRE_UYUMU:
            return {
                ...state,
                sifreEslesmeDurumu: action.payload
            };

        default:
            return state;
    }
}
