import React, { Component } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import BiContainer from './components/biContainer/BiContainer';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import Login from './components/login/Login';
import { login } from './components/login/UserAction';
import { connect } from 'react-redux';
// import BiSideBar from './components/bisidebar/BiSideBar';
// import UreticiYonetimSayfasi from './components/ureticiYonetimSayfasi/UreticiYonetimSayfasi';
// import koy from './components/koyYonetimSayfasi/KoyYonetimSayfasi';
// import kantar from './components/kantarYonetimSayfasi/KantarYonetimSayfasi';
// import bitenIslemler from './components/yoneticiBitenIslemlerSayfasi/YoneticiBitenIslemlerSayfasi';
// // import kantarlar from './components/kantarlar/Kantarlar';
// import alacak_verecek from './components/alacak_verecek_durumu/Alacak_verecek_durumu';
// import fabrika from './components/fabrikayaUlasanAraclar/FabrikayaUlasanAraclar';

class App extends Component {


  componentWillMount() {
    const session = sessionStorage.getItem("sessionToken");
    if (session) {
      this.props.state.UserReducer.isAuthenticated = true
    }
  }
  render() {
    return (
      <div className="App">


        <BrowserRouter basename={'/yonetici'}>
          <div>

            <Route exact path='/login' component={Login} isAuthenticated={this.props.state.UserReducer.isAuthenticated} />
            <PrivateRoute path='/' component={BiContainer} isAuthenticated={this.props.state.UserReducer.isAuthenticated} />
            {/* <BiSideBar />

            <Route exact path="/yonetici/UreticiYonetimSayfasi" component={UreticiYonetimSayfasi} />
            <Route exact path="/yonetici/KoyYonetimSayfasi" component={koy} />
            <Route exact path="/yonetici/KantarYonetimSayfasi" component={kantar} />
            <Route exact path="/yonetici/YoneticiBitenIslemlerSayfasi" component={bitenIslemler} />
            <Route exact path="/yonetici/Kantarlar" component={kantarlar} />
            <Route exact path="/yonetici/Alacak_verecek_durumu" component={alacak_verecek} />
            <Route exact path="/yonetici/FabrikayaUlasanAraclar" component={fabrika} /> */}



          </div>
        </BrowserRouter>

      </div>
    );
  }
}


const mapDispatchToProps = {
  login
};
const mapStateToProps = state => ({
  state: state
});
export default connect(mapStateToProps, mapDispatchToProps)(App);


