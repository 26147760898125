import React from "react";
import "./UyariMesajlariYonetimSayfasi.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getKantarListesi, getMesajAciliyetListesi, mesajGonder, getMesajListesi, deleteMesaj } from "./UyariMesajlariYonetimAction";
import { Icon, Table, Modal, Button, Input, Menu } from "semantic-ui-react";
import { connect } from "react-redux";

class UyariMesajlariYonetimSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            kantarAd: "",
            kantarMemuruAd: "",
            kantarMemuruSoyad: "",
            uyariAciklama: "",
            gorulmeZamani: " ",
            filter: "",
            filtered: [],
            alanKantarciId: " ",
            mesajAciliyetId: "",
            gonderenkullaniciId: "",
            mesajIcerik: "",
            selectedMesaj: {},
            mesajYazilmaZaman: "",
            open: false
        };
    }

    componentDidMount() {
        var today = new Date();
        today.setHours(today.getHours() + 3);
        var todayFinal = today.toISOString();
        this.setState({mesajYazilmaZaman: todayFinal})

        this.props.getMesajListesi();
    }

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.UyariMesajlariYonetimReducer.mesajList
        });

        if (next.state.UyariMesajlariYonetimReducer.gondermeDurumu === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.UyariMesajlariYonetimReducer.gondermeDurumu === "ERROR") {
            toast.error("HATA! Mesaj gönderilemedi lütfen tekrar deneyiniz !");
        }

        if (next.state.UyariMesajlariYonetimReducer.silmeDurum === "SUCCESS") {
            toast.success("Success Notification !");
            window.location.reload();
        } else if (next.state.UyariMesajlariYonetimReducer.silmeDurum === "ERROR") {
            toast.error("HATA! Seçilen mesaj bilgileri silinemedi! Lütfen tekrar deneyiniz !");
        }
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.UyariMesajlariYonetimReducer.mesajList;
        updatedList = this.props.state.UyariMesajlariYonetimReducer.mesajList.filter(function(item) {
            return item.alanKantarci.kantarAd.toLowerCase().includes(filter) || item.mesajAciliyet.aciliyetSeviye.toLowerCase().includes(filter) || item.mesajIcerik.toLowerCase().includes(filter);
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    deleteMesajModel = mesajBilgileri => {
        this.setState({ show: true, selectedMesaj: mesajBilgileri });
    };

    deleteMesaj = selectedMesaj => {
        this.props.deleteMesaj(this.state.selectedMesaj);

        if (this.props.state.UyariMesajlariYonetimReducer.silmeDurum == "SUCCESS") {
            this.setState({ show: false });
        } else if (this.props.state.UyariMesajlariYonetimReducer.silmeDurum == "ERROR") {
            this.setState({ show: true });
        }
    };

    show = size => () => {
        this.setState({ size, show: true });
    };

    render() {
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <Modal className="scrolling" size="tiny" style={{ height: 250, position: "initial" }} open={this.state.show} onClose={this.close}>
                    <Modal.Header>MESAJ SİL</Modal.Header>
                    <Modal.Content>
                        <p style={{ fontSize: 18 }}>Seçilen Kaydı Silmek İstediğinize Emin Misiniz?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            HAYIR
                        </Button>
                        <Button positive icon="checkmark" labelPosition="right" content="EVET" onClick={this.deleteMesaj} />
                    </Modal.Actions>
                    <ToastContainer />
                </Modal>{" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>UYARI MESAJLARI</h1>
                        </Menu.Header>
                    </Menu>
                </div>
                <div className="UyariMesajlariYonetim">
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />

                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>KANTAR ADI</Table.HeaderCell>
                                <Table.HeaderCell>MESAJ YAZILMA ZAMANI</Table.HeaderCell>
                                <Table.HeaderCell>MESAJ AÇIKLAMA</Table.HeaderCell>
                                <Table.HeaderCell>ACİLİYET DURUMU</Table.HeaderCell>
                                <Table.HeaderCell>MESAJ GÖRÜLME ZAMANI</Table.HeaderCell>
                                <Table.HeaderCell>SİL</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>{post.alanKantarci.kantarAd} </Table.Cell>
                                    <Table.Cell>
                                        {post.mesajYazilmaZaman.substring(0, 10)} {post.mesajYazilmaZaman.substring(11, 16)}
                                    </Table.Cell>
                                    <Table.Cell>{post.mesajIcerik}</Table.Cell>
                                    <Table.Cell>{post.mesajAciliyet.aciliyetSeviye}</Table.Cell>
                                    <Table.Cell>
                                        {post.mesajGorunmeZaman == null ? "" : post.mesajGorunmeZaman.substring(0, 10)}
                                        {post.mesajGorunmeZaman == null ? "" : " , "}
                                        {post.mesajGorunmeZaman == null ? "" : post.mesajGorunmeZaman.substring(11, 16)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button color="red" onClick={() => this.deleteMesajModel(post)}>
                                            SİL
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getKantarListesi,
    getMesajAciliyetListesi,
    mesajGonder,
    getMesajListesi,
    deleteMesaj
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UyariMesajlariYonetimSayfasi);
