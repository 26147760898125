import axios from "axios";

import { API_PATH } from "../config/Config";

export const ALACAK_VERECEK_LIST_GETIR = "ALACAK_VERECEK_LIST_GETIR";
export const KOY_LIST_GETIR = "KOY_LIST_GETIR";

export function getAlacakVerecekListesi(tarih, koyId) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/alacakverecek/findTarih/` + tarih + `/` + koyId)

            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: ALACAK_VERECEK_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (ALACAK VERECEK LİSTESİ)");
            });
    };
}

export function getKoyListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/koy/findall`)

            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: { koyAd: "Tüm Köyler", id: "Tüm Köyler" } }].concat(result))
            .then(result =>
                dispatch({
                    type: KOY_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY LİSTESİ)");
            });
    };
}
