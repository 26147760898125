import axios from "axios";

import { API_PATH } from "../config/Config";

export const KOY_LIST_GETIR = "KOY_LIST_GETIR";
export const TESLIM_ALINAN_GUL_MIKTARI_LISTESI = "TESLIM_ALINAN_GUL_MIKTARI_LISTESI";

export function getKoyListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/koy/findall`)

            .then(result => result.data)
            .then(result =>
                result.map(post => {
                    return { value: post };
                })
            )
            .then(result => [{ value: { koyAd: "Tüm Köyler", id: "Tüm Köyler" } }].concat(result))
            .then(result =>
                dispatch({
                    type: KOY_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (KÖY LİSTESİ)");
            });
    };
}

export function getTeslimAlinanGulMiktariListesi(date, koyId) {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/teslimAlinanGulMiktari/findall/` + date + `/` + koyId)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: TESLIM_ALINAN_GUL_MIKTARI_LISTESI,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (TESLİM ALINAN GÜL MİKTARI LİSTESİ)");
            });
    };
}
