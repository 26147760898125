import { ARAC_LIST_GETIR, CREATE_SUCCESS, CREATE_FAIL, DELETE_SUCCESS_ARAC, DELETE_FAIL_ARAC } from "./AracYonetimAction";

export default function aracYonetimReducer(
    state = {
        aracList: [],
        kayitDurumu: {},
        silmeDurum: {},
        error: ""
    },
    action
) {
    switch (action.type) {
        case ARAC_LIST_GETIR:
            return {
                ...state,
                aracList: action.payload
            };

        case CREATE_SUCCESS:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case CREATE_FAIL:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case DELETE_SUCCESS_ARAC:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case DELETE_FAIL_ARAC:
            return {
                ...state,
                silmeDurum: action.payload
            };

        default:
            return state;
    }
}
