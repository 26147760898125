import {
    KAZAN_LIST_GETIR,
    ISLEM_LIST_GETIR,
    DEVAM_EDEN_ISLEM_LIST_GETIR,
    CREATE_SUCCESS_YENI_DISTILASYON,
    CREATE_FAIL_YENI_DISTILASYON,
    CREATE_SUCCESS_DISTILASYON_BITIR,
    CREATE_FAIL_DISTILASYON_BITIR
} from "./DevamEdenIslemlerAction";

export default function DevamEdenIslemlerReducer(
    state = {
        kazanList: [],
        islemList: [],
        islem: {},
        devamEdenIslemlerList: [],
        yeniIslem: {},
        islemBitir: {},
        fetching: true,
        error: ""
    },
    action
) {
    switch (action.type) {
        case KAZAN_LIST_GETIR:
            return {
                ...state,
                kazanList: action.payload
            };

        case ISLEM_LIST_GETIR:
            return {
                ...state,
                islemList: action.payload
            };

        case DEVAM_EDEN_ISLEM_LIST_GETIR:
            return {
                ...state,
                devamEdenIslemlerList: action.payload
            };

        case CREATE_SUCCESS_YENI_DISTILASYON:
            return {
                ...state,
                yeniIslem: action.payload
            };
        case CREATE_FAIL_YENI_DISTILASYON:
            return {
                ...state,
                yeniIslem: action.payload
            };

        case CREATE_SUCCESS_DISTILASYON_BITIR:
            return {
                ...state,
                islemBitir: action.payload
            };
        case CREATE_FAIL_DISTILASYON_BITIR:
            return {
                ...state,
                islemBitir: action.payload
            };
        default:
            return state;
    }
}
