import React from "react";
import "./AlacakVerecekDurumSayfasi.css";
import { getAlacakVerecekListesi, getKoyListesi } from "./AlacakVerecekDurumAction";
import { Icon, Table, Input, Button, Menu, Label, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import Datetime from "react-datetime";

class AlacakVerecekDurumSayfasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alacakVerecek: {},
            koyAd: "",
            bulunduguKoy: "Tüm Köyler",
            gulBirimFiyati: 1,
            tarih: new Date(),
            tahminiGulMiktari: "",
            ureticiKoy: { id: "" },
            show: false,
            open: false,
            filter: "",
            filtered: []
        };
    }

    componentDidMount() {
        this.props.getAlacakVerecekListesi(this.state.tarih.getFullYear(), this.state.bulunduguKoy);
        this.props.getKoyListesi();
    }

    handleDate = date => {
        this.setState({ tarih: date._d });
    };

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false, show: false });
    };

    componentWillReceiveProps(next) {
        this.setState({
            filtered: next.state.AlacakVerecekDurumReducer.alacakVerecekgelenList
        });
    }

    handleFilter = event => {
        this.setState({
            filter: event.target.value
        });
        this.filterList(event.target.value, undefined);
    };

    filterList = (filter = this.state.filter) => {
        var updatedList = this.props.state.AlacakVerecekDurumReducer.alacakVerecekgelenList;
        updatedList = this.props.state.AlacakVerecekDurumReducer.alacakVerecekgelenList.filter(function(item) {
            return (
                item.ureticiAd.toLowerCase().includes(filter) ||
                item.ureticiSoyad.toLowerCase().includes(filter) ||
                item.ureticiTcKimlikNo.toLowerCase().includes(filter) ||
                item.koy.koyAd.toLowerCase().includes(filter) ||
                item.avansMiktari == filter
            );
        });
        this.setState({
            filtered: updatedList
        });
    };

    clearFilter = () => {
        this.setState({
            filter: ""
        });
        this.filterList("", undefined);
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeInModel = post => {
        this.setState({
            open: true,
            bulunduguKoy: post.bulunduguKoy,
            tahminiGulMiktari: post.tahminiGulMiktari
        });
    };

    handleChangeForm = e => {
        this.setState({ bulunduguKoy: e.target.value });
    };

    submit = () => {
        this.props.getAlacakVerecekListesi(this.state.tarih.getFullYear(), this.state.bulunduguKoy);
    };

    clearState = () => {
        this.setState({
            koyAd: "",
            bulunduguKoy: " ",
            gulBirimFiyati: 1,
            show: false,
            open: false
        });
    };

    onGulBirimFiyatiChange = e => {
        this.setState({ gulBirimFiyati: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            open: false
        });
    };

    render() {
        return (
            <div className="container-fluid">
                {" "}
                &nbsp;
                <div className="Top">
                    <Menu fixed="top" className="HeaderBar">
                        <Menu.Header>
                            <h1>ALACAK VERECEK DURUMU</h1>
                        </Menu.Header>
                    </Menu>
                </div>{" "}
                &nbsp;
                <div className="alacakVerecekDurumu">
                    <div style={{ textAlign: "center" }}>
                        <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                            TARİH:
                        </Label>
                        <Input>
                            <Datetime timeFormat={false} value={this.state.tarih} defaultValue={new Date()} onChange={this.handleDate} dateFormat="YYYY" closeOnSelect required />
                        </Input>

                        <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                            KÖY:
                        </Label>
                        <Dropdown
                            placeholder="Köy seçin."
                            required
                            style={{ margin: 5 }}
                            selection
                            defaultValue={"Tüm Köyler"}
                            value={this.state.bulunduguKoy}
                            onChange={this.handleChangeForm}
                            options={this.props.state.AlacakVerecekDurumReducer.koyList.map(team => ({ key: team.value.id, value: team.value.id, text: team.value.koyAd }))}
                        />

                        <Button style={{ margin: 5 }} onClick={this.submit} color="blue">
                            BUL
                        </Button>

                        <br />

                        <Label pointing="right" style={{ margin: 5, fontSize: 16, fontWeight: "bold" }}>
                            Gül Komisyonunun Belirlediği Gül Fiyatı (1 Kg İçin):
                        </Label>
                        <Input value={this.state.gulBirimFiyati} onChange={this.onGulBirimFiyatiChange} style={{ margin: 5 }} type="number" step="0.01" min="0" />
                    </div>
                </div>
                <div className="alacakVerecekDurumu">
                    <Input name="filter" placeholder="Ara..." value={this.state.filter} onChange={this.handleFilter} />
                    <Icon link name="times" onClick={this.clearFilter} />
                    <Table className="table table-striped">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>SIRA NO</Table.HeaderCell>
                                <Table.HeaderCell>ÜRETİCİ ADI SOYADI</Table.HeaderCell>
                                <Table.HeaderCell>ÜRETİCİ T.C. KİMLİK NO</Table.HeaderCell>
                                <Table.HeaderCell>BULUNDUĞU KÖY</Table.HeaderCell>
                                <Table.HeaderCell>VERİLEN AVANS MİKTARI (TL)</Table.HeaderCell>
                                <Table.HeaderCell>TAHMİNİ GÜL MİKTARI (KG)</Table.HeaderCell>
                                <Table.HeaderCell>SEZON SONU GÜL MİKTARI (KG)</Table.HeaderCell>
                                <Table.HeaderCell>HESAPLANAN ÜCRET (TL)</Table.HeaderCell>
                                <Table.HeaderCell>ALACAK/VERECEK DURUMU (TL) </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           {this.state.filtered.slice().reverse().map(post => (
                                <Table.Row key={post.id}>
                                    <Table.Cell>{this.state.filtered.length - this.state.filtered.indexOf(post)} </Table.Cell>
                                    <Table.Cell>
                                        {post.ureticiAd} {post.ureticiSoyad}
                                    </Table.Cell>
                                    <Table.Cell>{post.ureticiTcKimlikNo}</Table.Cell>
                                    <Table.Cell>{post.koy.koyAd}</Table.Cell>
                                    <Table.Cell>{post.avansMiktari} TL</Table.Cell>
                                    <Table.Cell>{post.tahminiGulMiktari} KG</Table.Cell>
                                    <Table.Cell>{post.sezonSonuGulMiktari} KG</Table.Cell>
                                    <Table.Cell>{post.sezonSonuGulMiktari * this.state.gulBirimFiyati} </Table.Cell>
                                    <Table.Cell>{post.sezonSonuGulMiktari * this.state.gulBirimFiyati - post.avansMiktari} </Table.Cell>
                                </Table.Row>
                            ))}

                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell>
                                    <h4> TOPLAM VERİLEN AVANS (TL): </h4>
                                </Table.Cell>
                                <Table.Cell>
                                    <h4>
                                        {" "}
                                        {this.props.state.AlacakVerecekDurumReducer.alacakVerecekgelenList
                                            .map(line => line.avansMiktari)
                                            .reduce((prev, next) => parseInt(prev, 10) + parseInt(next, 10), 0)}{" "}
                                        TL{" "}
                                    </h4>
                                </Table.Cell>
                                <Table.Cell>
                                    <h4> TOPLAM GÜL MİKTARI (KG): </h4>
                                </Table.Cell>
                                <Table.Cell>
                                    <h4>
                                        {" "}
                                        {this.props.state.AlacakVerecekDurumReducer.alacakVerecekgelenList
                                            .map(line => line.sezonSonuGulMiktari)
                                            .reduce((prev, next) => parseInt(prev, 10) + parseInt(next, 10), 0)
                                            .toFixed(3)}{" "}
                                        KG{" "}
                                    </h4>
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getAlacakVerecekListesi,
    getKoyListesi
};

const mapStateToProps = state => {
    return {
        state: state
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlacakVerecekDurumSayfasi);
