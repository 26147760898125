import { BITEN_ISLEMLER_LIST_GETIR, ISLEM_LIST_GETIR, KAZAN_LIST_GETIR } from "./UretilenUrunlerAction";

export default function UretilenUrunlerReducer(
    state = {
        islemList: [],
        bitenIslemlerList: [],
        kazanList: [],
        error: ""
    },
    action
) {
    switch (action.type) {
        case BITEN_ISLEMLER_LIST_GETIR:
            return {
                ...state,
                bitenIslemlerList: action.payload
            };

        case ISLEM_LIST_GETIR:
            return {
                ...state,
                islemList: action.payload
            };

        case KAZAN_LIST_GETIR:
            return {
                ...state,
                kazanList: action.payload
            };

        default:
            return state;
    }
}
