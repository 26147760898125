import { LIST_GETIR_KOY, CREATE_SUCCESS_KOY, CREATE_FAIL_KOY, DELETE_SUCCESS_KOY, DELETE_FAIL_KOY } from "./KoyYonetimAction";

export default function KoyYonetimReducer(
    state = {
        koygelenList: [],
        koy: {},
        kayitDurumu: {},
        silmeDurum: {},
        error: ""
    },
    action
) {
    switch (action.type) {
        case LIST_GETIR_KOY:
            return {
                ...state,
                koygelenList: action.payload
            };
        case CREATE_SUCCESS_KOY:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case CREATE_FAIL_KOY:
            return {
                ...state,
                kayitDurumu: action.payload
            };

        case DELETE_SUCCESS_KOY:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case DELETE_FAIL_KOY:
            return {
                ...state,
                silmeDurum: action.payload
            };

        default:
            return state;
    }
}
