import { KOY_LIST_GETIR, TESLIM_ALINAN_GUL_MIKTARI_LISTESI } from "./TeslimAlinanGulMiktariAction";

export default function ureticiYonetimReducer(
    state = {
        koyList: [],
        teslimEdilenGulList: [],
        fetching: true,
        error: ""
    },
    action
) {
    switch (action.type) {
        case KOY_LIST_GETIR:
            return {
                ...state,
                koyList: action.payload
            };
        case TESLIM_ALINAN_GUL_MIKTARI_LISTESI:
            return {
                ...state,
                teslimEdilenGulList: action.payload
            };
        default:
            return state;
    }
}
