import axios from "axios";

import { API_PATH } from "../config/Config";

export const ARAC_LIST_GETIR = "URETICI_LIST_GETIR";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const CREATE_FAIL = "CREATE_FAIL";
export const DELETE_SUCCESS_ARAC = "DELETE_SUCCESS_ARAC";
export const DELETE_FAIL = "DELETE_FAIL";
export const DELETE = "DELETE";
export const DELETE_FAIL_ARAC = "DELETE_FAIL_ARAC";

export function getAracListesi() {
    return dispatch => {
        axios
            .get(`${API_PATH}/api/arac/findall`)
            .then(result => result.data)
            .then(result =>
                dispatch({
                    type: ARAC_LIST_GETIR,
                    payload: result
                })
            )
            .catch(() => {
                alert("SİSTEM HATASI! (ARAÇ LİSTESİ)");
            });
    };
}

function aracKaydetmeKontrolu(sonuc) {
    if (sonuc.status === 200) {
        return {
            type: CREATE_SUCCESS,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: CREATE_FAIL,
            payload: "ERROR"
        };
    }
}

export function aracKaydet(aracBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/arac/create`, {
            method: "POST",
            data: aracBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(aracKaydetmeKontrolu(result, aracBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (ARAÇ KAYDETME)");
            });
    };
}

function silmeKontrolu(sonuc, aracBilgileri) {
    if (sonuc.status === 200) {
        return {
            type: DELETE_SUCCESS_ARAC,
            payload: "SUCCESS"
        };
    } else {
        return {
            type: DELETE_FAIL_ARAC,
            payload: "ERROR"
        };
    }
}

export function deleteArac(aracBilgileri) {
    return dispatch => {
        const hash = sessionStorage.getItem("sessionToken");

        axios(`${API_PATH}/api/arac/delete`, {
            method: "DELETE",
            data: aracBilgileri,
            headers: {
                Authorization: `Basic ${hash}`
            }
        })
            .then(result => result)
            .then(result => dispatch(silmeKontrolu(result, aracBilgileri)))
            .catch(() => {
                alert("SİSTEM HATASI! (ARAÇ SİLME)");
            });
    };
}
