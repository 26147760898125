import { LIST_GETIR_KANTAR, CREATE_SUCCESS_KANTAR, CREATE_FAIL_KANTAR, KOY_LIST_GETIR, DELETE_SUCCESS_KANTAR, DELETE_FAIL_KANTAR } from "./KantarYonetimAction";

export default function KantarYonetimReducer(
    state = {
        kantargelenList: [],
        kantar: {},
        kayitDurumu: {},
        silmeDurum: {},
        koyList: [],
        error: ""
    },
    action
) {
    switch (action.type) {
        case LIST_GETIR_KANTAR:
            return {
                ...state,
                kantargelenList: action.payload
            };
        case CREATE_SUCCESS_KANTAR:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case CREATE_FAIL_KANTAR:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case DELETE_SUCCESS_KANTAR:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case DELETE_FAIL_KANTAR:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case KOY_LIST_GETIR:
            return {
                ...state,
                koyList: action.payload
            };

        default:
            return state;
    }
}
