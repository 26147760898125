import { CREATE_SUCCESS_URETICI, CREATE_FAIL_URETICI, LIST_GETIR_URETICI, KOY_LIST_GETIR, DELETE_FAIL_URETICI, DELETE_SUCCESS_URETICI } from "./UreticiYonetimAction";

export default function ureticiYonetimReducer(
    state = {
        ureticiList: [],
        koyList: [],
        fetching: true,
        uretici: {},
        kayitDurumu: {},
        silmeDurum: {},
        error: ""
    },
    action
) {
    switch (action.type) {
        case LIST_GETIR_URETICI:
            return {
                ...state,
                ureticiList: action.payload
            };
        case CREATE_SUCCESS_URETICI:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case CREATE_FAIL_URETICI:
            return {
                ...state,
                kayitDurumu: action.payload
            };
        case KOY_LIST_GETIR:
            return {
                ...state,
                koyList: action.payload
            };

        case DELETE_SUCCESS_URETICI:
            return {
                ...state,
                silmeDurum: action.payload
            };
        case DELETE_FAIL_URETICI:
            return {
                ...state,
                silmeDurum: action.payload
            };
        default:
            return state;
    }
}
